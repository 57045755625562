<template>
  <div class="interests-component">
    <div class="component-nav-icon">
      <!-- <img src="@/assets/icons/interests_header_icon.svg" alt srcset /> -->
      <InterestsHeaderIcon />
      <div class="c-n-title">
        <span>INTERESTS</span>
        <span>&nbsp;</span>
      </div>
    </div>

    <div class="i-c-content">
      <v-container class="elevation-0 i-c-c-container">
        <v-card class="i-c-c-c-card" flat tile>
          <v-row dense>
            <v-col class="pa-4 i-c-c-c-col" cols="12" sm="6" md="4" lg="4">
              <!-- <img src="@/assets/icons/travelling_interest.svg" alt srcset /> -->
              <TravellingInterest />
            </v-col>
            <v-col class="pa-4 i-c-c-c-col" cols="12" sm="6" md="4" lg="4">
              <!-- <img src="@/assets/icons/music_interest.svg" alt srcset /> -->
              <MusicInterest />
            </v-col>
            <v-col class="pa-4 i-c-c-c-col" cols="12" sm="6" md="4" lg="4">
              <!-- <img src="@/assets/icons/riding_interest.svg" alt srcset /> -->
              <RidingInterest />
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </div>
  </div>
</template>

<script>
import InterestsHeaderIcon from "../SVG/InterestsHeaderIcon";
import TravellingInterest from "../SVG/TravellingInterest";
import MusicInterest from "../SVG/MusicInterest";
import RidingInterest from "../SVG/RidingInterest";
export default {
  name: "Interests",
  components: {
    InterestsHeaderIcon, TravellingInterest, MusicInterest, RidingInterest
  }
}
</script>

<style lang="scss">
:root {
  --interests--t-c-content-bg: #fff;
  --interests--t-c-c-container-bg: #fff;
}

:root.dark-theme {
  --interests--t-c-content-bg: #181922;
  --interests--t-c-c-container-bg: #181922;
}

.interests-component {
  .i-c-content {
    background-color: var(--interests--t-c-content-bg);
    padding: 40px 32px;
  }

  .i-c-c-c-card {
    background-color: var(--interests--t-c-c-container-bg);
    display: flex !important;
    justify-content: space-around !important;
  }

  .i-c-c-c-col {
    display: flex;
    justify-content: center;

    svg {
      width: 90%;
      height: 90%;
    }
  }
}
</style>
