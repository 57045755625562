<template>
  <svg class="interests-header-svg" width="130" height="130" viewBox="0 0 130 130">
    <g id="Group_1282" data-name="Group 1282" transform="translate(-570 -24)">
      <path id="Path_2048" data-name="Path 2048"
        d="M14.333,27a3.333,3.333,0,1,0,3.333,3.333A3.333,3.333,0,0,0,14.333,27Z" transform="translate(559 23.667)"
        fill="#6c63ff" />
      <path id="Path_2049" data-name="Path 2049"
        d="M77.667,12a1.667,1.667,0,1,0,1.667,1.667A1.667,1.667,0,0,0,77.667,12Z"
        transform="translate(602.333 13.667)" />
      <path id="Path_2050" data-name="Path 2050"
        d="M74.667,13a61.667,61.667,0,1,0,61.667,61.667A61.667,61.667,0,0,0,74.667,13Z" transform="translate(560 14)" />
      <path id="Path_2051" data-name="Path 2051"
        d="M85.667,11a6.667,6.667,0,1,0,6.667,6.667A6.667,6.667,0,0,0,85.667,11Z" transform="translate(604.333 13)" />
      <path id="Path_2052" data-name="Path 2052"
        d="M88.333,22a3.333,3.333,0,1,0,3.333,3.333A3.333,3.333,0,0,0,88.333,22Z" transform="translate(608.333 20.333)"
        fill="#6c63ff" />
      <path id="Path_2053" data-name="Path 2053"
        d="M127.667,84A3.333,3.333,0,1,0,131,87.333,3.333,3.333,0,0,0,127.667,84Zm-110-25a6.667,6.667,0,1,0,6.667,6.667A6.667,6.667,0,0,0,17.667,59Z"
        transform="translate(559 45)" />
      <path id="Path_2054" data-name="Path 2054"
        d="M26.333,85a3.333,3.333,0,1,0,3.333,3.333A3.333,3.333,0,0,0,26.333,85Z" transform="translate(567 62.333)"
        fill="#6c63ff" />
      <path id="Path_2055" data-name="Path 2055"
        d="M20.167,63a4.167,4.167,0,1,0,4.167,4.167A4.167,4.167,0,0,0,20.167,63Zm4.167,25A1.667,1.667,0,1,0,26,89.667,1.667,1.667,0,0,0,24.333,88Zm98.333-55a1.667,1.667,0,1,0,1.667,1.667A1.667,1.667,0,0,0,122.667,33Z"
        transform="translate(562.333 27.667)" fill="#fff" />
      <g id="hobbies" transform="translate(597 51)">
        <ellipse id="Ellipse_128" data-name="Ellipse 128" cx="3.929" cy="3.924" rx="3.929" ry="3.924"
          transform="translate(19.715 19.696)" />
        <ellipse id="Ellipse_129" data-name="Ellipse 129" cx="3.929" cy="3.924" rx="3.929" ry="3.924"
          transform="translate(7.929 7.924)" />
        <ellipse id="Ellipse_130" data-name="Ellipse 130" cx="3.929" cy="3.924" rx="3.929" ry="3.924"
          transform="translate(7.929 31.468)" />
        <ellipse id="Ellipse_131" data-name="Ellipse 131" cx="3.929" cy="3.924" rx="3.929" ry="3.924"
          transform="translate(31.501 7.924)" />
        <path id="Path_2377" data-name="Path 2377"
          d="M78.388,55.544A5.413,5.413,0,0,0,73.7,54.526h0a5.1,5.1,0,0,0-2.929,2.28,1.132,1.132,0,0,1-1.132.519,1.174,1.174,0,0,1-.964-.841L66.27,47.927a2.443,2.443,0,0,0-3.013-1.688L58.045,47.7l-.356.508.35-.439a6.1,6.1,0,0,1,2.118,3.12c1.07,3.8-1.933,7.983-6.7,9.321s-9.509-.667-10.58-4.468a6.1,6.1,0,0,1,.18-3.766l-.148-.455-.008-.005.112.405L37.226,53.55a1.842,1.842,0,0,0-1.273,2.269l2.572,9.137a1.173,1.173,0,0,1-.383,1.219,1.13,1.13,0,0,1-1.238.147,5.1,5.1,0,0,0-3.69-.421,5.4,5.4,0,0,0-3.465,3.307,9.049,9.049,0,0,0-.232,5.877C30.689,79.248,34.252,81.9,37.466,81a5.1,5.1,0,0,0,2.929-2.28,1.123,1.123,0,0,1,.961-.531,1.281,1.281,0,0,1,.172.012,1.178,1.178,0,0,1,.964.841l2.572,9.137a1.845,1.845,0,0,0,2.272,1.273l5.79-1.626.007-.069a6.11,6.11,0,0,1-2.118-3.12c-1.07-3.8,1.934-7.983,6.7-9.321s9.509.667,10.579,4.469a6.1,6.1,0,0,1-.179,3.765l.194.595-.152-.539,5.79-1.626a1.84,1.84,0,0,0,1.274-2.269l-2.572-9.137a1.172,1.172,0,0,1,.383-1.219,1.132,1.132,0,0,1,1.238-.147,5.1,5.1,0,0,0,3.689.421c3.21-.9,4.869-5.022,3.7-9.185A9.06,9.06,0,0,0,78.388,55.544Z"
          transform="translate(-11.028 -18.52)" />
        <path id="Path_2378" data-name="Path 2378"
          d="M55.389,58.453a4.868,4.868,0,0,0,1.38,2.228c.112.034.227.068.345.091a3.925,3.925,0,1,0-1.931-6.689,1.179,1.179,0,0,1,.12.1,1.16,1.16,0,0,1,.239,1.193A4.984,4.984,0,0,0,55.389,58.453Z"
          transform="translate(-22.457 -21.532)" />
        <path id="Path_2379" data-name="Path 2379"
          d="M25.9,33.951l5.465-1.535a5.044,5.044,0,1,1,4.86,7.953,9.108,9.108,0,0,0,5.907.244,10.271,10.271,0,0,0,1.035-.359,3.935,3.935,0,0,0,.125-.938V7.924A3.931,3.931,0,0,0,39.358,4H7.929A3.931,3.931,0,0,0,4,7.924V39.316a3.931,3.931,0,0,0,3.929,3.924h17.5L23.843,37.6A2.965,2.965,0,0,1,25.9,33.951ZM35.43,6.8a5.045,5.045,0,1,1-5.051,5.045A5.048,5.048,0,0,1,35.43,6.8ZM11.857,40.438a5.045,5.045,0,1,1,5.051-5.045A5.048,5.048,0,0,1,11.857,40.438Zm0-23.544a5.045,5.045,0,1,1,5.051-5.045A5.048,5.048,0,0,1,11.857,16.893Zm6.735,6.727a5.051,5.051,0,1,1,5.051,5.045,5.048,5.048,0,0,1-5.051-5.045Z" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "InterestsHeaderIcon"
}
</script>

<style lang="scss" scoped>
:root {
  .interests-header-svg {

    #Path_2379,
    #Path_2378,
    #Path_2377,
    #Ellipse_131,
    #Ellipse_130,
    #Ellipse_129,
    #Ellipse_128 {
      fill: #ef7974;
    }

    #Path_2053,
    #Path_2051,
    #Path_2049 {
      fill: #ec615b;
    }

    #Path_2050 {
      fill: #6c63ff;
      opacity: 0.147;
    }
  }
}

:root.dark-theme {
  .interests-header-svg {

    #Path_2379,
    #Path_2378,
    #Path_2377,
    #Ellipse_131,
    #Ellipse_130,
    #Ellipse_129,
    #Ellipse_128 {
      fill: #F8BF7B;
    }

    #Path_2053,
    #Path_2051,
    #Path_2049 {
      fill: #F8BF7B;
    }

    #Path_2050 {
      fill: #1e2129;
      opacity: 1;
    }
  }
}
</style>