<template>
  <svg class="interest_music_svg" width="260" height="260" viewBox="0 0 260 260">
    <g id="Group_1655" data-name="Group 1655" transform="translate(-752 -306)">
      <path id="Path_2201" data-name="Path 2201"
        d="M316.989,174.074c-4.364-17.53-16.4-32.376-20.221-50.042-3.465-16.062.2-32.736.1-49.179-.14-22.907-8.647-46.78-26.7-60.395C252.573,1.169,228.927-.5,207.235,2.5s-42.73,10.06-64.521,12.364c-9.924,1.054-20.088,1.135-29.547,4.391-15.931,5.492-27.718,19.237-37.25,33.382C69.146,62.671,62.987,73.445,60.505,85.361c-5.175,24.906,6.332,50.3,5.987,75.723-.16,12.018-2.967,24.07-1.231,35.951,3.007,20.616,19.237,36.889,37.163,46.848,24.97,13.88,54.152,18.353,82.561,17.163C232.319,259.034,335.4,248.111,316.989,174.074Z"
        transform="translate(692.834 304.77)" style="isolation: isolate" />
      <text id="Music_Txt" transform="translate(882 526)" font-size="18" font-family="Noteworthy-Bold, Noteworthy"
        font-weight="700">
        <tspan x="-22.77" y="0">Music</tspan>
      </text>
      <g id="Group_1273" data-name="Group 1273" transform="translate(-4.558 15)">
        <path id="Path_2226" data-name="Path 2226"
          d="M179.72,29.129c-.08-1.228-5.27,2.345-5.27,2.345s4.4-3.574,4.019-4.467-5.33,3.015-5.33,3.015,4.522-3.685,3.823-4.473-5.526,3.356-5.526,3.356,4.924-4.584,3.045-4.7c-.844-.048-7.471,4.722-14.65,10.45,2.01,2.659,4.019,5.228,6.19,7.711,2.3-2.521,4.115-4.526,4.361-4.861.739-1.005,8.074-.335,8.1-1.564s-4.833-1.787-4.833-1.787S179.8,30.357,179.72,29.129Z"
          transform="translate(706.048 314.015)" fill="#fff" />
        <path id="Path_2227" data-name="Path 2227"
          d="M165.729,42.4h0a.215.215,0,0,1-.166-.074c-1.929-2.212-3.9-4.664-6.2-7.727a.255.255,0,0,1-.05-.181.27.27,0,0,1,.09-.165C164.709,30,173,23.757,174.185,23.757h0a.6.6,0,0,1,.613.4c.161.479-.4,1.361-1.14,2.25,1.377-.973,2.753-1.723,3.185-1.234a.552.552,0,0,1,.131.431,4.02,4.02,0,0,1-1.125,1.76,4.25,4.25,0,0,1,2.236-.925.427.427,0,0,1,.3.25c.246.558-.7,1.7-1.8,2.765,1.181-.67,2.376-1.213,2.834-.968a.486.486,0,0,1,.256.415h0c.075,1.149-3.919,3.808-5.632,4.893,1.568.229,4.386.8,4.361,1.946,0,.835-1.663.973-4.351,1.122-1.567.09-3.517.2-3.8.585s-1.658,1.9-4.376,4.882A.24.24,0,0,1,165.729,42.4Zm-5.863-7.908c2.165,2.872,4.019,5.206,5.868,7.312,3.185-3.489,4.019-4.457,4.175-4.653.382-.532,1.819-.638,4.135-.771,1.367-.08,3.909-.223,3.919-.649,0-.622-2.391-1.282-4.627-1.542a.232.232,0,0,1-.2-.191.251.251,0,0,1,.105-.261c2.979-1.829,6.029-4.127,5.989-4.786h0c-.311-.229-2.562.936-4.919,2.558a.223.223,0,0,1-.3-.078.255.255,0,0,1,.035-.321c2.266-1.84,4.1-3.792,3.934-4.175-.337-.175-2.628,1.244-4.979,3.111a.223.223,0,0,1-.324-.045.254.254,0,0,1,.043-.343c1.442-1.181,3.748-3.324,3.808-4.052v-.058c-.281-.33-2.778,1.282-5.23,3.377a.227.227,0,0,1-.322-.037.244.244,0,0,1,0-.335c1.753-1.638,3.547-3.723,3.386-4.2,0-.048-.116-.069-.206-.074h0C173.476,24.241,167.844,28.14,159.866,34.489Z"
          transform="translate(706.308 314.228)" fill="#fff" />
        <path id="Path_2228" data-name="Path 2228"
          d="M121.122,62.112c-1.412,4.941,7.515,26.876,7.515,26.876l9.86-13.353c-.235-.447-5.267-10.955-5.267-10.955S141.11,56.6,145.5,51.571c-2.065-2.484-4.015-5.073-5.917-7.711C131.154,50.9,121.9,59.389,121.122,62.112Z"
          transform="translate(725.386 304.814)" fill="#fff" />
        <path id="Path_2232" data-name="Path 2232"
          d="M281.754,152.414c-.352,1.473-6.124,12.386-6.124,12.386l7.536,4.313s6.818-12.093,7.4-12.9,5.526-7.775,6.2-10.221-4.08-4.255-6.029-4.058S282.106,150.941,281.754,152.414Z"
          transform="translate(648.426 258.903)" fill="#fff" />
        <path id="Path_2233" data-name="Path 2233"
          d="M282.914,169.167a.188.188,0,0,1-.106-.032l-7.536-4.313a.232.232,0,0,1-.111-.149.241.241,0,0,1,0-.186c2.01-3.76,5.823-11.168,6.1-12.322h0c.372-1.564,7.129-10.471,9.169-10.636a8.133,8.133,0,0,1,5.813,2.409,2.178,2.178,0,0,1,.462,1.957c-.718,2.659-6.185,10.232-6.235,10.307-.573.782-7.32,12.764-7.39,12.87a.213.213,0,0,1-.166.1Zm-7.21-4.659,7.129,4.09c.919-1.633,6.752-11.966,7.3-12.721,1.151-1.6,5.557-7.913,6.159-10.142a1.661,1.661,0,0,0-.377-1.516,7.711,7.711,0,0,0-5.416-2.234c-1.839.17-8.461,9.041-8.767,10.3-.332,1.388-5.195,10.647-6.029,12.226Z"
          transform="translate(648.669 259.093)" fill="#fff" />
        <path id="Path_2234" data-name="Path 2234"
          d="M27.437,1.207,20.7,9.9,5.607,0,0,11.793S20.556,28.129,23.47,26.565,35.218,5.714,35.218,5.714Z"
          transform="matrix(1, -0.017, 0.017, 1, 900.355, 415.133)" fill="#fff" />
        <path id="Path_2236" data-name="Path 2236" d="M295.715,130.56l-.065,21.251h9.5V130.56Z"
          transform="translate(638.465 264.223)" fill="#231f20" />
        <path id="Path_2237" data-name="Path 2237"
          d="M304.931,151.856h-9.5a.215.215,0,0,1-.158-.07.242.242,0,0,1-.063-.169l.06-21.273a.233.233,0,0,1,.226-.234h9.435a.228.228,0,0,1,.221.234v21.273a.241.241,0,0,1-.063.169A.215.215,0,0,1,304.931,151.856Zm-9.274-.468H304.7v-20.8h-8.993Z"
          transform="translate(638.684 264.433)" fill="#231f20" />
        <path id="Path_2238" data-name="Path 2238" d="M297.65,134.09l-.05,16.7h7.546v-16.7Z"
          transform="translate(637.494 262.57)" fill="#fff" />
        <path id="Path_2239" data-name="Path 2239"
          d="M304.932,150.818H297.4a.215.215,0,0,1-.161-.069.241.241,0,0,1-.065-.176l.045-16.694a.238.238,0,0,1,.231-.239h7.5a.233.233,0,0,1,.226.239v16.7a.246.246,0,0,1-.07.173A.219.219,0,0,1,304.932,150.818Zm-7.315-.5H304.7V134.124h-7.034Z"
          transform="translate(637.708 262.781)" fill="#231f20" />
        <path id="Path_2240" data-name="Path 2240"
          d="M286.542,155.088s-1.417-7.158.708-8.28,9.43-2.436,8.3-.681-5.089,2.84-5.089,2.84.814,2.5-.08,4.281"
          transform="translate(643.227 257.334)" fill="#fff" />
        <path id="Path_2241" data-name="Path 2241"
          d="M286.319,155.119a.225.225,0,0,1-.221-.191c-.06-.3-1.432-7.355.829-8.546,1.834-.963,7.752-2.2,8.616-1.159a.661.661,0,0,1-.03.835c-1,1.6-4.1,2.606-4.989,2.872a6.549,6.549,0,0,1-.166,4.255.225.225,0,0,1-.311.1.258.258,0,0,1-.1-.33,6.326,6.326,0,0,0,.065-4.084.252.252,0,0,1,0-.191.244.244,0,0,1,.146-.122c.04,0,3.889-1.064,4.959-2.739.075-.117.1-.2.06-.245-.543-.659-6.029.213-8.069,1.276-1.613.851-.965,6.068-.583,8.009a.251.251,0,0,1-.031.183.226.226,0,0,1-.145.1Z"
          transform="translate(643.45 257.544)" fill="#231f20" />
        <path id="Path_2242" data-name="Path 2242"
          d="M152.615,118.064,139.356,99.382l-11.045,11.03,4.828,10.223s-9.2,23.928-9.2,63.4c0,0,42.795-.2,53.566-.2l-14.067-23.16s5.186-14.017,7-16.7l10.285,6.409,7.688-11.507Z"
          transform="translate(723.906 279.815)" />
        <path id="Path_2245" data-name="Path 2245"
          d="M217.151,115.246a8.92,8.92,0,0,1-1.974,7.387c-1.045,1.2-3.336,1.516-3.8-.431a10.366,10.366,0,0,1,1.191-7.568c1-1.75,2.929-2.749,4.165-.5A4.793,4.793,0,0,1,217.151,115.246Z"
          transform="translate(680.524 272.522)" fill="#231f20" />
        <path id="Path_2246" data-name="Path 2246"
          d="M212.875,123.627a2.285,2.285,0,0,1-.573-.069,1.923,1.923,0,0,1-1.362-1.505,10.659,10.659,0,0,1,1.2-7.738,3.356,3.356,0,0,1,2.628-1.915,2.238,2.238,0,0,1,1.919,1.42,5.2,5.2,0,0,1,.462,1.165h0a9.18,9.18,0,0,1-2.01,7.6A3.056,3.056,0,0,1,212.875,123.627Zm1.864-10.759a2.945,2.945,0,0,0-2.226,1.686,10.145,10.145,0,0,0-1.14,7.387,1.451,1.451,0,0,0,1.04,1.159,2.521,2.521,0,0,0,2.376-.83,8.719,8.719,0,0,0,1.929-7.164h0a4.628,4.628,0,0,0-.417-1.064,1.865,1.865,0,0,0-1.542-1.181Z"
          transform="translate(680.746 272.725)" fill="#231f20" />
        <path id="Path_2247" data-name="Path 2247"
          d="M189.565,116.5c-1,4.079-2.01,13.37-8.415,8.509a8.433,8.433,0,0,1-1.7-1.925c-3.155-4.451-2.256-7.259-.623-12.147.7-2.127,1.311-4.255,2.01-6.382a54.426,54.426,0,0,1,2.8-6.142c-.035.069,2.171-7.547,5.346-9.073s5.586-.941,8.4,0,3.362,4.74,3.819,6.357c.049.182-.479.533,0,1.09.287.327,1.583.907,1.379,1.438-.649,1.707-4.086,3.742-4.222,4.922a37.431,37.431,0,0,1-3.607,11.817C193.052,118.509,189.565,116.5,189.565,116.5Z"
          transform="translate(697.333 282.241)" fill="#fff" />
        <path id="Path_2248" data-name="Path 2248"
          d="M184.156,129.346a5.805,5.805,0,0,1-3.371-1.425,8.707,8.707,0,0,1-1.748-1.978c-3.251-4.59-2.271-7.52-.653-12.37.337-1.021.658-2.069.97-3.079.337-1.09.683-2.212,1.055-3.313a36.082,36.082,0,0,1,1.984-4.483c.281-.564.563-1.127.829-1.691h0a.23.23,0,0,1,.132-.121.215.215,0,0,1,.174.015h0a10.985,10.985,0,0,0,6.1-1c3.125-1.516,8.918,4.319,8.732,5.972a37.474,37.474,0,0,1-3.632,11.9,3.752,3.752,0,0,1-2.175,2.09,4.421,4.421,0,0,1-3.08-.292c-.1.42-.206.893-.311,1.388-.663,3.047-1.568,7.227-3.964,8.185a2.867,2.867,0,0,1-1.045.207Zm-.6-27.957c-.241.532-.5,1.021-.754,1.532a35.814,35.814,0,0,0-1.959,4.425c-.367,1.064-.713,2.212-1.05,3.3s-.633,2.063-.975,3.09c-1.623,4.861-2.512,7.536.593,11.918a8.274,8.274,0,0,0,1.648,1.877c1.578,1.2,2.924,1.6,4.019,1.159,2.165-.867,3.014-4.893,3.678-7.834.136-.617.256-1.2.382-1.691a.246.246,0,0,1,.132-.159.224.224,0,0,1,.2,0,4.165,4.165,0,0,0,3.014.388,3.277,3.277,0,0,0,1.894-1.84,36.925,36.925,0,0,0,3.587-11.732c.113-.99-4.5-4.835-4.5-4.835s-7.754.657-9.9.4Z"
          transform="translate(697.563 279.535)" fill="#fff" />
        <path id="Path_2249" data-name="Path 2249"
          d="M194.286,138.088a7.524,7.524,0,0,1-4.522-3.489,6.134,6.134,0,0,1-.558-4.839,15.255,15.255,0,0,0,6.144,4.4Z"
          transform="translate(691.558 264.597)" fill="#231f20" />
        <path id="Path_2250" data-name="Path 2250"
          d="M194.054,138.123H194a7.682,7.682,0,0,1-4.672-3.6,6.365,6.365,0,0,1-.573-5.047.219.219,0,0,1,.372-.064,14.981,14.981,0,0,0,6.029,4.329.224.224,0,0,1,.133.113.246.246,0,0,1,.018.18l-1.04,3.919a.223.223,0,0,1-.216.17Zm-4.994-8.1a6.234,6.234,0,0,0,.658,4.254,7.244,7.244,0,0,0,4.18,3.313l.939-3.483A15.09,15.09,0,0,1,189.06,130.024Z"
          transform="translate(691.789 264.796)" fill="#231f20" />
        <path id="Path_2251" data-name="Path 2251"
          d="M201.111,119.2a3.318,3.318,0,0,1-1.454-.47.189.189,0,0,1-.067-.254.178.178,0,0,1,.246-.069c.314.185,1.222.6,1.629.331.265-.176-.024-1.9-.635-3.775a.187.187,0,0,1,.114-.235.179.179,0,0,1,.228.117c.366,1.12,1.144,3.775.485,4.194a1,1,0,0,1-.546.159Z"
          transform="translate(686.568 273.077)" fill="#231f20" />
        <path id="Path_2252" data-name="Path 2252"
          d="M195.806,122.789a2.038,2.038,0,0,1-.717-.119,1.788,1.788,0,0,1-1.081-1.564c-.006-.044.036-.083.093-.089a.131.131,0,0,1,.078.017.077.077,0,0,1,.039.054,1.663,1.663,0,0,0,.956,1.446,2.816,2.816,0,0,0,1.822-.157.135.135,0,0,1,.08-.01.108.108,0,0,1,.065.037.071.071,0,0,1-.036.11A3.6,3.6,0,0,1,195.806,122.789Z"
          transform="translate(691.031 271.567)" fill="#231f20" stroke="#231f20" stroke-width="0.5" />
        <path id="Path_2253" data-name="Path 2253"
          d="M199.264,109.31a.227.227,0,0,1-.221-.218c-.035-.532-.181-.856-.442-.994-.5-.277-1.442.223-1.753.441a.215.215,0,0,1-.309-.066.245.245,0,0,1,.063-.327c.055-.037,1.341-.92,2.2-.468a1.541,1.541,0,0,1,.688,1.383.243.243,0,0,1-.054.17.217.217,0,0,1-.152.08Z"
          transform="translate(687.421 276.927)" fill="#231f20" />
        <path id="Path_2254" data-name="Path 2254"
          d="M210.583,113.728a.226.226,0,0,1-.221-.213,2.485,2.485,0,0,0-.729-1.6c-.543-.378-1.176.484-1.181.495a.219.219,0,0,1-.147.09.212.212,0,0,1-.164-.047.237.237,0,0,1-.088-.155.244.244,0,0,1,.043-.175c.306-.425,1.07-1.1,1.784-.6a2.941,2.941,0,0,1,.924,1.957.243.243,0,0,1-.05.172.217.217,0,0,1-.151.083Z"
          transform="translate(681.484 274.885)" fill="#231f20" />
        <path id="Path_2255" data-name="Path 2255"
          d="M177.9,105.835a4.972,4.972,0,0,0,.4,2.473,3.321,3.321,0,0,0,3.909,1.516c3.753-1.175,5.557-8.732,2.718-11.7-2.638-2.755-5.632,1.6-6.25,4.164A18.069,18.069,0,0,0,177.9,105.835Z"
          transform="translate(697.068 279.821)" fill="#231f20" />
        <path id="Path_2256" data-name="Path 2256"
          d="M180.983,110.021a3.475,3.475,0,0,1-3.1-1.792,5.094,5.094,0,0,1-.427-2.6h0a11.866,11.866,0,0,1,.5-2.617c.09-.324.181-.649.261-.973.437-1.787,2.06-4.558,3.974-5.116a2.552,2.552,0,0,1,2.648.84c1.372,1.431,1.819,4.021,1.171,6.759s-2.17,4.786-3.979,5.318a3.706,3.706,0,0,1-1.05.175Zm-3.085-4.366a4.676,4.676,0,0,0,.377,2.356,3.105,3.105,0,0,0,3.652,1.4c1.663-.532,3.07-2.436,3.673-4.994s.206-5-1.05-6.313a2.132,2.132,0,0,0-2.221-.729c-1.728.532-3.261,3.154-3.663,4.786-.08.33-.171.659-.261.989a11.371,11.371,0,0,0-.5,2.5Z"
          transform="translate(697.289 280.013)" fill="#231f20" />
        <path id="Path_2257" data-name="Path 2257"
          d="M204.436,98.4a.482.482,0,0,1-.266-.08.55.55,0,0,1-.166-.729c.055-.1,5.627-9.8,6.582-15.843A7.046,7.046,0,0,0,208,75.474c-2.753-2.531-6.863-3.664-9.772-2.691-5.175,1.734-7.928,14.359-7.958,14.465a.5.5,0,0,1-.6.4.533.533,0,0,1-.382-.633c.116-.532,2.914-13.333,8.636-15.247,3.271-1.09,7.682.1,10.731,2.9,2.291,2.127,3.331,4.68,2.924,7.249-1,6.254-6.486,15.816-6.717,16.22A.5.5,0,0,1,204.436,98.4Z"
          transform="translate(691.395 291.936)" fill="#231f20" />
        <path id="Path_2258" data-name="Path 2258"
          d="M186.209,195.313a16.265,16.265,0,0,1-5.3-.909,17.191,17.191,0,0,1-5.562-3.425A75.192,75.192,0,0,1,167.726,183c-1.507-1.75-3.065-3.558-4.692-5.228-5.1-5.233-10.164-8.02-15.072-8.275-6.6-.351-12.455,3.643-17.79,8.009-.5.425-1.045.867-1.578,1.314-4.316,3.622-9.209,7.722-15.238,6.759-4.11-.654-7.3-3.654-9.249-6.052a29.959,29.959,0,0,1-5.923-22.554c1.176-8.828,5.823-15.05,13.816-18.5,6.617-2.851,12.862-6.839,18.905-10.695l.05-.027c3.743-2.393,7.717-5.456,8.787-10.051a.258.258,0,0,1,.312-.2.275.275,0,0,1,.191.33c-1.115,4.786-5.185,7.929-9.018,10.376l-.045.032c-6.059,3.866-12.324,7.866-18.976,10.732-7.817,3.366-12.359,9.445-13.51,18.082-1.075,8.041,1.1,16.311,5.788,22.1,1.894,2.335,4.984,5.249,8.943,5.877,5.813.931,10.41-2.925,14.851-6.648l1.583-1.319c5.416-4.43,11.359-8.482,18.122-8.121,5.024.266,10.2,3.1,15.389,8.424,1.638,1.686,3.2,3.5,4.713,5.254a72.336,72.336,0,0,0,7.566,7.94,16.758,16.758,0,0,0,5.4,3.334c6.787,2.319,13.565.037,20.192-6.786,9.832-10.147,15.374-25.527,15.2-42.247-.08-7.807-1.457-13.907-4.21-18.656-1.11-1.92-2.512-4.058-4.793-5.127-1.166-.532-3.9-.734-4.954.835a4.332,4.332,0,0,0-.5,2.207,11.706,11.706,0,0,1-.105,1.3.256.256,0,0,1-.284.231.266.266,0,0,1-.219-.3c.06-.457.08-.867.106-1.26a4.686,4.686,0,0,1,.593-2.484c1.226-1.824,4.23-1.633,5.567-1.01,2.432,1.127,3.863,3.345,5.024,5.318,2.8,4.834,4.2,11.024,4.286,18.933.176,16.848-5.416,32.387-15.348,42.63C196.559,192.7,191.4,195.307,186.209,195.313Z"
          transform="translate(736.863 270.339)" fill="#231f20" />
        <path id="Path_2259" data-name="Path 2259"
          d="M176.739,41.814a.255.255,0,0,1-.191-.09.283.283,0,0,1,0-.378,4.133,4.133,0,0,1,3.261-1.335,3.478,3.478,0,0,1,2.281,1.377.282.282,0,0,1-.055.372.241.241,0,0,1-.187.048.25.25,0,0,1-.164-.107,3.022,3.022,0,0,0-1.929-1.175,3.676,3.676,0,0,0-2.874,1.213.241.241,0,0,1-.141.074Z"
          transform="translate(697.765 306.626)" fill="#6d5856" />
        <rect id="Rectangle_380" data-name="Rectangle 380" width="1.095" height="1.723"
          transform="translate(938.149 393.293)" fill="#231f20" />
        <path id="Path_2262" data-name="Path 2262"
          d="M304.551,129.511h-1.1a.233.233,0,0,1-.226-.234v-1.723a.241.241,0,0,1,.067-.167.215.215,0,0,1,.16-.067h1.1a.228.228,0,0,1,.221.234v1.723A.228.228,0,0,1,304.551,129.511Zm-.874-.473h.653v-1.25h-.653Z"
          transform="translate(634.693 265.74)" fill="#231f20" />
        <path id="Path_2263" data-name="Path 2263"
          d="M186.292,109.7l-.362-.372a8.09,8.09,0,0,0,1.291-9.147l.432-.271A8.662,8.662,0,0,1,186.292,109.7Z"
          transform="translate(693.061 278.573)" fill="#fff" />
        <path id="Path_2264" data-name="Path 2264"
          d="M219.187,125.049l-.467-.2a16.354,16.354,0,0,0,1.1-6.988l.5-.074A16.735,16.735,0,0,1,219.187,125.049Z"
          transform="translate(676.745 270.201)" fill="#fff" />
        <path id="Path_2274" data-name="Path 2274" d="M.01.117,5.371-.036,5.356.481,0,.633Z"
          transform="translate(854.546 373.18) rotate(-45.63)" fill="#6d5856" />
        <path id="Path_2275" data-name="Path 2275" d="M.077.008.6,0,.5,4.351l-.526.012Z"
          transform="translate(921.134 425.037) rotate(-63.91)" fill="#fff" />
        <path id="Path_2265" data-name="Path 2265"
          d="M67.721,119.6c-.211-.053-.437-.09-.643-.165a1.971,1.971,0,0,1-1.377-1.388,2.23,2.23,0,0,1,.3-1.728,3.646,3.646,0,0,1,2.422-1.681,3.328,3.328,0,0,1,1.969.149V101.981l11.9-2.521v15.662a2.731,2.731,0,0,1-.879,2.127,3.564,3.564,0,0,1-3.632.947,1.893,1.893,0,0,1-1.377-1.383,2.162,2.162,0,0,1,.3-1.723,3.615,3.615,0,0,1,2.432-1.681,3.267,3.267,0,0,1,1.949.149v-8.807l-.844.175q-4.245.9-8.476,1.792c-.156.032-.181.1-.181.245V116.3a2.913,2.913,0,0,1-1.161,2.441,3.862,3.862,0,0,1-1.9.819.832.832,0,0,0-.106.037Z"
          transform="translate(752.915 278.783)" />
        <path id="Path_2266" data-name="Path 2266"
          d="M252.712,57.939a2.466,2.466,0,0,0,.3-.17,1.036,1.036,0,0,0,.5-.878,1.089,1.089,0,0,0-.362-.814,1.8,1.8,0,0,0-1.4-.489,1.7,1.7,0,0,0-.955.34l-1.583-6.339L243,49.984l.035.133c.628,2.537,1.266,5.073,1.894,7.616a1.351,1.351,0,0,0,.693.931,1.814,1.814,0,0,0,1.914-.037,1,1,0,0,0,.5-.872,1.09,1.09,0,0,0-.357-.814,1.814,1.814,0,0,0-1.417-.495,1.7,1.7,0,0,0-.945.34l-1.055-4.318.442-.032,4.416-.282c.08,0,.1,0,.121.1.382,1.537.774,3.079,1.145,4.621a1.44,1.44,0,0,0,.874,1.064,1.955,1.955,0,0,0,1.045.144h.055Z"
          transform="translate(664.663 302.132)" fill="#ec615b" />
        <path id="Path_2267" data-name="Path 2267"
          d="M303.671,218.32v-.3a1.682,1.682,0,0,0-.286-.771,3.281,3.281,0,0,0-.97-.973,3.461,3.461,0,0,0-1.261-.532,2.148,2.148,0,0,0-.864,0h-.075a.68.68,0,0,1,0-.106c.05-.207.1-.41.156-.606l1-3.723c.367-1.351.739-2.7,1.105-4.052v-.08l-.352-.032a5.946,5.946,0,0,0-2.808.532,4.136,4.136,0,0,0-1.964,1.824c-.126.234-.221.484-.327.734-.07.16-.126.33-.191.495a.286.286,0,0,0,0,.069l.126-.08a9.4,9.4,0,0,1,1.251-.734,4.245,4.245,0,0,1,2.135-.4,3.72,3.72,0,0,1,.924.218h0a.471.471,0,0,0,0,.08q-.914,3.361-1.814,6.717a.326.326,0,0,0,0,.122,1.974,1.974,0,0,0,.452,1.064,3.343,3.343,0,0,0,1.176.963l.392.181.362.112h.1a2.162,2.162,0,0,0,.9,0,1.04,1.04,0,0,0,.648-.372,2.89,2.89,0,0,0,.151-.25Z"
          transform="translate(637.875 228.372)" />
        <path id="Path_2268" data-name="Path 2268"
          d="M65.79,218a.947.947,0,0,1,.055-.388,2.117,2.117,0,0,1,.5-.931,4.2,4.2,0,0,1,1.437-1.037,4.389,4.389,0,0,1,1.718-.409,2.863,2.863,0,0,1,1.11.186l.1.037a1.282,1.282,0,0,0,0-.138,7.469,7.469,0,0,0-.08-.808q-.286-2.516-.578-5.015-.316-2.728-.638-5.446v-.106a1.856,1.856,0,0,1,.457.037,7.863,7.863,0,0,1,3.517,1.271,5.533,5.533,0,0,1,2.165,2.765,9.965,9.965,0,0,1,.276,1.01c.06.223.1.452.151.681a.18.18,0,0,1,0,.09l-.146-.133a12.617,12.617,0,0,0-1.467-1.213,5.509,5.509,0,0,0-2.658-.973,4.779,4.779,0,0,0-1.231.074h0a.691.691,0,0,1,0,.1q.5,4.51,1.03,9.041a.4.4,0,0,1,0,.154,2.524,2.524,0,0,1-.789,1.292,4.156,4.156,0,0,1-1.7.979c-.176.059-.357.1-.533.149l-.5.064h-.08a2.8,2.8,0,0,1-1.166-.17,1.349,1.349,0,0,1-.754-.612,2.775,2.775,0,0,1-.151-.356Z"
          transform="translate(752.842 229.867)" />
        <path id="Path_2269" data-name="Path 2269"
          d="M271.41,95.2c.065-.138.121-.282.2-.41a2.452,2.452,0,0,1,.924-.846,4.858,4.858,0,0,1,2.01-.574,5.239,5.239,0,0,1,2.09.25,3.426,3.426,0,0,1,1.186.675l.1.08a.982.982,0,0,0,.06-.154c.075-.314.146-.628.211-.947l1.206-5.9q.648-3.191,1.291-6.414l.025-.128c.176.08.342.149.5.229a9.861,9.861,0,0,1,3.487,2.893,6.9,6.9,0,0,1,1.427,4.026c0,.416-.02.833-.06,1.248,0,.283-.055.533-.086.836a.239.239,0,0,1-.03.1l-.121-.207a15.556,15.556,0,0,0-1.206-1.973,6.869,6.869,0,0,0-2.643-2.207,5.522,5.522,0,0,0-1.417-.42h-.035a.866.866,0,0,1-.025.122l-2.17,10.6a.55.55,0,0,1-.065.175,2.874,2.874,0,0,1-1.367,1.133,4.886,4.886,0,0,1-2.281.4c-.221,0-.437-.032-.658-.053l-.578-.128-.1-.043a3.45,3.45,0,0,1-1.251-.675,1.77,1.77,0,0,1-.628-1.005q-.029-.23-.035-.463Z"
          transform="translate(650.546 287.506)" />
        <path id="Path_2270" data-name="Path 2270"
          d="M93.246,153.39c-2.587,0-2.592,4.255,0,4.255S95.839,153.39,93.246,153.39Z"
          transform="translate(740.146 253.534)" />
        <path id="Path_2271" data-name="Path 2271"
          d="M275.63,136.461a1.14,1.14,0,0,0-.256-.468l-.206-.165a.964.964,0,0,0-.5-.149h-.281a.7.7,0,0,0-.387.1.719.719,0,0,0-.327.218.837.837,0,0,0-.206.34.82.82,0,0,0-.085.409l.035.287a1.113,1.113,0,0,0,.256.468l.206.165a.963.963,0,0,0,.5.144h.281a.7.7,0,0,0,.387-.09.8.8,0,0,0,.327-.218,1.24,1.24,0,0,0,.291-.755C275.655,136.653,275.64,136.557,275.63,136.461Z"
          transform="translate(649.547 261.826)" />
        <path id="Path_2272" data-name="Path 2272"
          d="M109.736,55.78a1.335,1.335,0,0,0,0,2.659A1.335,1.335,0,0,0,109.736,55.78Z"
          transform="translate(731.579 299.234)" />
        <path id="Path_2273" data-name="Path 2273"
          d="M256.148,232a1.335,1.335,0,0,0,0,2.659A1.335,1.335,0,0,0,256.148,232Z"
          transform="translate(658.724 216.73)" />
        <g id="Group_1272" data-name="Group 1272" transform="translate(880.385 367.084)">
          <path id="Path_2276" data-name="Path 2276"
            d="M2.73,8.3S-1.254,8.1.466,3.368c1.1-3.077,9.651-3.3,9.651-3.3S10.271,5.851,2.73,8.3Z"
            transform="matrix(-0.799, -0.602, 0.602, -0.799, 18.225, 22.131)" fill="#231f20" />
          <path id="Path_2277" data-name="Path 2277"
            d="M13.764.091l-.1,0L7.687,1.26a.289.289,0,0,0-.175.1L.146,10.237a.368.368,0,0,0-.018.432,7.92,7.92,0,0,0,11.171,2.3c2.586-1.535,3.882-4.22,3.732-7.765A16.269,16.269,0,0,0,14.077.294.35.35,0,0,0,13.764.091ZM7.972,1.957l5.607-1.1a16.046,16.046,0,0,1,.841,4.373c.11,3.3-1.024,5.705-3.427,7.136C5.126,15.843,1.622,11.614.865,10.55Z"
            transform="matrix(-0.799, -0.602, 0.602, -0.799, 11.928, 20.619)" fill="#231f20" />
          <path id="Path_2278" data-name="Path 2278"
            d="M9.909.065C8.515.018,6.52.5,5.16,3.184A19.713,19.713,0,0,1,.192,9.663a.358.358,0,0,0-.1.416.35.35,0,0,0,.352.232L4.12,9.933C10.664,7.818,11.728.849,11.739.78a.382.382,0,0,0-.224-.406A4.939,4.939,0,0,0,9.909.065ZM1.469,9.493A22.294,22.294,0,0,0,5.741,3.554C7.435.174,10.114.677,11.023.935,10.736,2.3,9.287,7.5,3.944,9.24Z"
            transform="matrix(-0.799, -0.602, 0.602, -0.799, 15.374, 22.064)" fill="#231f20" />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "MusicInterest"
}
</script>

<style lang="scss" scoped>
:root {
  .interest_music_svg {
    #Path_2201 {
      fill: #efeeff;
      opacity: 0.996;
    }

    #Path_2242 {
      fill: #ef7974;
    }

    #Path_2265,
    #Path_2266,
    #Path_2267,
    #Path_2268,
    #Path_2269,
    #Path_2270,
    #Path_2271,
    #Path_2272,
    #Path_2273 {
      fill: #ec615b;
    }

    #Music_Txt {
      fill: #ec615b;
    }
  }
}

:root.dark-theme {
  .interest_music_svg {
    #Path_2201 {
      fill: #56575E;
      opacity: 1;
    }

    #Path_2242 {
      fill: #F8BF7B;
    }

    #Path_2265,
    #Path_2266,
    #Path_2267,
    #Path_2268,
    #Path_2269,
    #Path_2270,
    #Path_2271,
    #Path_2272,
    #Path_2273 {
      fill: #e59c49;
    }

    #Music_Txt {
      fill: #F8BF7B;
    }
  }
}
</style>