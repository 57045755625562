<template>
  <svg class="interest_travelling_svg" width="260" height="260" viewBox="0 0 260 260">
    <g id="Group_1653" data-name="Group 1653" transform="translate(-310 -306.393)">
      <path id="Path_2200" data-name="Path 2200"
        d="M312.956,167.246c.482-19.391-7.5-37.757-15.386-55.255C288.4,91.658,278.617,70.676,261.99,56.6c-20.133-17.086-47.873-21.458-73.348-17.305s-49.252,15.97-72.15,28.537c-13.525,7.427-26.973,15.211-38.983,25.153-7.478,6.192-14.551,13.491-18.091,22.867-3.093,8.131-3.256,17.132-3.377,25.894l-1.275,91.841c-.069,4.994-.121,10.134,1.533,14.808,2.231,6.284,7.211,10.93,12.009,15.3,9.735,8.872,20.193,18.11,32.883,20.123,13.37,2.122,26.655-4.207,40.172-4.573,16.239-.412,31.41,7.738,46.969,12.714a123.815,123.815,0,0,0,60.1,3.823c14.318-2.662,28.722-8.232,38.836-19.29,5.824-6.4,9.959-14.278,13.241-22.446a137.6,137.6,0,0,0,8.968-35.233c1-8.452,4.962-17.379,5.238-25.675C315.007,184.688,312.732,176.045,312.956,167.246Z"
        transform="translate(255.26 268.561)" style="isolation: isolate" />
      <g id="Group_1274" data-name="Group 1274" transform="translate(-19.124 -9)">
        <path id="Path_2124" data-name="Path 2124"
          d="M219.017,206.645H54.833a.3.3,0,0,1-.283-.3V197.8a.29.29,0,0,1,.283-.3H219.017a.29.29,0,0,1,.283.3v8.55a.3.3,0,0,1-.283.3ZM55.145,206.05H218.734v-7.956H55.116Z"
          transform="translate(322.573 261.282)" />
        <path id="Path_2125" data-name="Path 2125"
          d="M219.017,140.9H54.833a.3.3,0,0,1-.283-.3V56.867a.29.29,0,0,1,.283-.3H219.017a.29.29,0,0,1,.283.3v83.738a.3.3,0,0,1-.283.3Zm-163.872-.594H218.734V57.164H55.116Z"
          transform="translate(322.573 318.473)" />
        <path id="Path_2126" data-name="Path 2126"
          d="M146.053,140.9a.29.29,0,0,1-.283-.3V56.867a.283.283,0,1,1,.566,0v83.738A.3.3,0,0,1,146.053,140.9Z"
          transform="translate(283.007 318.473)" />
        <path id="Path_2127" data-name="Path 2127"
          d="M253.513,140.9a.3.3,0,0,1-.283-.3V56.867a.283.283,0,1,1,.566,0v83.738A.29.29,0,0,1,253.513,140.9Z"
          transform="translate(236.396 318.473)" />
        <path id="Path_2128" data-name="Path 2128"
          d="M219.017,127.594H54.833a.29.29,0,0,1-.283-.3.3.3,0,0,1,.283-.3H219.017a.3.3,0,0,1,.283.3A.29.29,0,0,1,219.017,127.594Z"
          transform="translate(322.573 289.892)" />
        <path id="Path_2129" data-name="Path 2129"
          d="M186.444,88.964a18.765,18.765,0,0,1,5.255.345,9.309,9.309,0,0,1,6.585,5,15.031,15.031,0,0,1,.849,5.865l.085,6.881v2.2c0,1.676.04,3.351.034,5.027a27.878,27.878,0,0,1-.391,5.193c-.566,2.757-1.976,5.472-4.366,6.744a9.866,9.866,0,0,1-5.1.9,18.338,18.338,0,0,1-10.459-3.684,14.816,14.816,0,0,1-5.628-9.792c-.215-1.884.345-3.963.408-5.9a61.2,61.2,0,0,1,.5-6.6c.532-3.832,1.552-8.83,5.051-10.915a9.532,9.532,0,0,1,4.015-1C184.349,89.106,185.4,89.005,186.444,88.964Z"
          transform="translate(271.081 305.349)" fill="#231f20" />
        <path id="Path_2130" data-name="Path 2130"
          d="M190.015,127.209h-.81a18.407,18.407,0,0,1-10.628-3.761,15.027,15.027,0,0,1-5.736-9.994,15.3,15.3,0,0,1,.181-3.654c.1-.761.2-1.551.221-2.294a60.649,60.649,0,0,1,.5-6.637c.566-4.076,1.7-9.026,5.2-11.123a9.785,9.785,0,0,1,4.128-1.04c.968-.1,2.067-.2,3.143-.261h0a19.193,19.193,0,0,1,5.334.351,9.677,9.677,0,0,1,6.8,5.163,15.437,15.437,0,0,1,.878,5.983l.108,9.085c0,1.682.04,3.375.028,5.039a28.242,28.242,0,0,1-.4,5.247c-.629,3.226-2.265,5.758-4.53,6.946A9.15,9.15,0,0,1,190.015,127.209Zm-3.76-38.17c-1.059.053-2.157.16-3.12.256a9.4,9.4,0,0,0-3.9.963c-3.313,1.973-4.366,6.756-4.915,10.7a60.6,60.6,0,0,0-.5,6.572,23.493,23.493,0,0,1-.227,2.377,15.4,15.4,0,0,0-.181,3.506,14.425,14.425,0,0,0,5.521,9.59,17.887,17.887,0,0,0,10.306,3.625,9.38,9.38,0,0,0,4.955-.862c2.084-1.111,3.618-3.494,4.219-6.536a27.849,27.849,0,0,0,.385-5.134V109.11l-.108-9.133a14.457,14.457,0,0,0-.832-5.74,8.933,8.933,0,0,0-6.387-4.831,18.555,18.555,0,0,0-5.181-.339Zm0-.3Z"
          transform="translate(271.281 305.559)" fill="#231f20" />
        <path id="Path_2131" data-name="Path 2131"
          d="M141.081,94.966l-8.618,6.31L125.6,87.509s.43-5.71-.43-6.34-1.291,2-1.291,2-1.9-5.532-2.5-5.3-2.831,1.676-2.831,3.119,7.752,28.04,9.989,29.115,19.592-9.679,19.592-9.679"
          transform="translate(294.813 309.834)" fill="#fff" />
        <path id="Path_2132" data-name="Path 2132"
          d="M128.634,110.258a1.15,1.15,0,0,1-.493-.089c-1.048-.511-3.058-5.425-5.985-14.605C120.089,89.082,118,81.613,118,80.775c0-1.658,2.412-3.167,3.029-3.4a.39.39,0,0,1,.323,0c.606.3,1.631,2.935,2.214,4.551.17-.553.436-1.188.832-1.331a.654.654,0,0,1,.68.1c.9.66.663,5.051.566,6.536l6.7,13.393,8.358-6.12a.275.275,0,0,1,.382.083.307.307,0,0,1-.059.4l-8.647,6.316a.278.278,0,0,1-.226.048.309.309,0,0,1-.187-.154l-6.886-13.767a.259.259,0,0,1-.034-.16c.2-2.662.142-5.746-.311-6.078-.079-.053-.113-.042-.13-.036-.294.131-.606,1.153-.719,1.848a.289.289,0,0,1-.255.244.272.272,0,0,1-.289-.2c-.764-2.222-1.8-4.753-2.208-5.092-.634.279-2.582,1.6-2.582,2.81,0,1.664,7.888,27.927,9.824,28.854,1.54.749,12.673-5.538,19.332-9.667a.276.276,0,0,1,.388.1.305.305,0,0,1-.1.407C146.312,101.5,132.122,110.258,128.634,110.258Z"
          transform="translate(295.052 310.044)" fill="#231f20" />
        <rect id="Rectangle_374" data-name="Rectangle 374" width="2.758" height="3.708" rx="1.379"
          transform="translate(497.695 491.004)" fill="#231f20" />
        <path id="Path_2133" data-name="Path 2133"
          d="M268.931,255.514h-.566a1.345,1.345,0,0,1-.972-.433,1.482,1.482,0,0,1-.393-1.028v-1.408a1.411,1.411,0,0,1,1.376-1.444h.566a1.411,1.411,0,0,1,1.376,1.444v1.408a1.482,1.482,0,0,1-.4,1.037,1.344,1.344,0,0,1-.986.425Zm-.566-3.7a.83.83,0,0,0-.81.85v1.39a.83.83,0,0,0,.81.85h.566a.83.83,0,0,0,.81-.85v-1.408a.83.83,0,0,0-.81-.85Z"
          transform="translate(230.423 239.489)" fill="#231f20" />
        <rect id="Rectangle_375" data-name="Rectangle 375" width="2.758" height="3.708" rx="1.379"
          transform="translate(485.509 491.004)" fill="#231f20" />
        <path id="Path_2134" data-name="Path 2134"
          d="M247.4,255.526h-.566a1.411,1.411,0,0,1-1.376-1.444v-1.408a1.411,1.411,0,0,1,1.376-1.444h.566a1.417,1.417,0,0,1,1.382,1.444v1.39a1.488,1.488,0,0,1-.4,1.033A1.35,1.35,0,0,1,247.4,255.526Zm-.566-3.7a.83.83,0,0,0-.81.85v1.39a.83.83,0,0,0,.81.85h.566a.836.836,0,0,0,.815-.85v-1.408a.836.836,0,0,0-.815-.85Z"
          transform="translate(239.766 239.477)" fill="#231f20" />
        <path id="Path_2135" data-name="Path 2135" d="M227.71,194.236l8.958-5.336h20.266l5.912,30.1-7.322,6.869Z"
          transform="translate(247.465 264.772)" fill="#fff" />
        <path id="Path_2136" data-name="Path 2136"
          d="M255.3,225.946a.255.255,0,0,1-.2-.1l-27.82-31.628a.307.307,0,0,1-.074-.249.3.3,0,0,1,.142-.214l8.958-5.348a.279.279,0,0,1,.136-.042h20.272a.287.287,0,0,1,.277.238l5.912,30.1a.306.306,0,0,1-.085.285l-7.322,6.869A.3.3,0,0,1,255.3,225.946Zm-27.367-31.854,27.389,31.141,6.993-6.536-5.838-29.709h-19.96Z"
          transform="translate(247.685 264.987)" fill="#231f20" />
        <path id="Path_2137" data-name="Path 2137" d="M255.524,229.508h-21.7L227.71,197.88h21.7Z"
          transform="translate(247.465 261.127)" fill="#fff" />
        <path id="Path_2138" data-name="Path 2138"
          d="M255.308,229.611h-21.7a.281.281,0,0,1-.277-.238l-6.115-31.617a.327.327,0,0,1,.057-.25.278.278,0,0,1,.221-.107h21.682a.281.281,0,0,1,.277.238l6.132,31.611a.3.3,0,0,1-.057.25A.267.267,0,0,1,255.308,229.611Zm-21.466-.594h21.121l-6-31.034H227.839Z"
          transform="translate(247.682 261.322)" fill="#231f20" />
        <path id="Path_2139" data-name="Path 2139" d="M243.53,249.67l.515,1.806h15.328l-.3-1.806Z"
          transform="translate(240.603 240.11)" fill="#231f20" />
        <path id="Path_2140" data-name="Path 2140"
          d="M259.155,251.587H243.827a.285.285,0,0,1-.272-.214l-.515-1.783a.3.3,0,0,1,.04-.261.284.284,0,0,1,.227-.119h15.561a.282.282,0,0,1,.278.244l.3,1.783a.289.289,0,0,1-.062.244A.272.272,0,0,1,259.155,251.587Zm-15.119-.594h14.785l-.2-1.188H243.691Z"
          transform="translate(240.822 240.297)" fill="#231f20" />
        <path id="Path_2141" data-name="Path 2141"
          d="M243.015,184.031h-10.8a.286.286,0,0,1-.277-.232l-4.875-23.256a.3.3,0,0,1,.051-.25.284.284,0,0,1,.221-.113h11.082a.281.281,0,0,1,.277.238l4.6,23.256a.308.308,0,0,1-.062.25A.256.256,0,0,1,243.015,184.031Zm-10.572-.594h10.221l-4.473-22.662h-10.5Z"
          transform="translate(247.749 276.427)" fill="#231f20" />
        <path id="Path_2142" data-name="Path 2142"
          d="M265.781,194.886a.269.269,0,0,1-.238-.137.3.3,0,0,1-.043-.223.292.292,0,0,1,.122-.187l6.6-4.522a.281.281,0,0,1,.4.089.306.306,0,0,1-.085.41l-6.6,4.522A.306.306,0,0,1,265.781,194.886Z"
          transform="translate(231.076 264.418)" fill="#231f20" />
        <path id="Path_2143" data-name="Path 2143"
          d="M238.786,160.774H225a.29.29,0,0,1-.283-.3.3.3,0,0,1,.283-.3h13.782a.3.3,0,0,1,.283.3A.29.29,0,0,1,238.786,160.774Z"
          transform="translate(248.762 276.427)" fill="#231f20" />
        <path id="Path_2144" data-name="Path 2144" d="M162.112,235.331l-2.123,5.312-4.858-2.234,2.208-5.859Z"
          transform="translate(278.947 247.058)" fill="#fff" />
        <path id="Path_2145" data-name="Path 2145"
          d="M156.888,232.128l4.773,2.781-2.123,5.312-4.858-2.24,2.208-5.853m0-.594a.5.5,0,0,0-.187.036.579.579,0,0,0-.34.339l-2.191,5.835a.605.605,0,0,0,.3.761l4.858,2.24a.6.6,0,0,0,.227.048.51.51,0,0,0,.21-.042.58.58,0,0,0,.311-.321l2.123-5.312a.608.608,0,0,0-.249-.749l-4.768-2.781a.546.546,0,0,0-.277-.077Z"
          transform="translate(279.38 247.48)" fill="#231f20" />
        <path id="Path_2146" data-name="Path 2146"
          d="M151.288,243.883l-2.627,7.005s-13.845-5.223-14.722-6.162,2.146-2.08,2.729-1.9a13.983,13.983,0,0,0,3.647.7,43.993,43.993,0,0,0,5.215-2.442Z"
          transform="translate(288.207 243.592)" fill="#231f20" />
        <path id="Path_2147" data-name="Path 2147"
          d="M148.428,251a.275.275,0,0,1-.1,0c-1.421-.541-13.93-5.27-14.841-6.233a.8.8,0,0,1-.125-1,4.094,4.094,0,0,1,3.131-1.384,14.54,14.54,0,0,0,3.533.7,37.669,37.669,0,0,0,5.125-2.412.271.271,0,0,1,.243,0l5.776,2.793a.3.3,0,0,1,.142.374l-2.627,7.005A.271.271,0,0,1,148.428,251Zm-12.191-8.081a3.983,3.983,0,0,0-2.39,1.129.2.2,0,0,0-.039.149.191.191,0,0,0,.079.13c.617.648,8.839,3.9,14.377,6l2.429-6.477-5.408-2.614a35.608,35.608,0,0,1-5.181,2.377,14.521,14.521,0,0,1-3.771-.713A.228.228,0,0,0,136.237,242.918Z"
          transform="translate(288.44 243.778)" fill="#231f20" />
        <path id="Path_2148" data-name="Path 2148" d="M206.195,213.43l4.405,3.434-3.233,4.415-4.887-3.654Z"
          transform="translate(258.409 254.817)" fill="#fff" />
        <path id="Path_2149" data-name="Path 2149"
          d="M205.719,213.024l4.405,3.434-3.233,4.415L202,217.219l3.715-4.195m0-.594a.554.554,0,0,0-.419.19l-3.709,4.195a.612.612,0,0,0-.153.463.6.6,0,0,0,.238.428l4.887,3.648a.549.549,0,0,0,.328.113.557.557,0,0,0,.453-.232l3.228-4.415a.616.616,0,0,0,.113-.446.6.6,0,0,0-.221-.392l-4.411-3.434A.549.549,0,0,0,205.719,212.43Z"
          transform="translate(258.862 255.223)" fill="#231f20" />
        <path id="Path_2150" data-name="Path 2150"
          d="M213.167,218.16,219,222.509s-8.143,12.858-9.224,13.541-1.41-2.68-1.1-3.226a15.729,15.729,0,0,0,1.523-3.565,50.665,50.665,0,0,0-1.008-5.9Z"
          transform="translate(255.768 252.897)" fill="#231f20" />
        <path id="Path_2151" data-name="Path 2151"
          d="M209.3,236.24a.749.749,0,0,1-.532-.25,4.576,4.576,0,0,1-.527-3.523,15.683,15.683,0,0,0,1.484-3.434,44,44,0,0,0-1.008-5.8.327.327,0,0,1,.057-.25l3.964-5.211a.274.274,0,0,1,.385-.059l5.849,4.355a.3.3,0,0,1,.074.4c-.815,1.331-8.211,12.929-9.309,13.631a.794.794,0,0,1-.436.137Zm0-12.995a44.085,44.085,0,0,1,.985,5.859,15.816,15.816,0,0,1-1.563,3.666,4.347,4.347,0,0,0,.442,2.8.181.181,0,0,0,.272,0c.742-.475,5.725-8.069,8.986-13.215l-5.413-4.023Z"
          transform="translate(255.977 253.1)" fill="#231f20" />
        <path id="Path_2152" data-name="Path 2152"
          d="M160.361,139.7s-9.405,26.322-6.954,28.242,27.746,23.322,27.746,23.322l6.2-8.366s-18.018-14.171-18.533-15.859,4.473-25.669,4.473-25.669Z"
          transform="translate(279.871 284.738)" fill="#231f20" />
        <path id="Path_2153" data-name="Path 2153"
          d="M180.922,191.377a.286.286,0,0,1-.181-.065c-.249-.214-25.317-21.42-27.746-23.316-2.548-2,5.889-25.871,6.863-28.586a.288.288,0,0,1,.294-.19l12.944,1.652a.281.281,0,0,1,.2.125.3.3,0,0,1,.045.232c-2,9.608-4.847,24.332-4.485,25.514s11.5,10.256,18.437,15.71a.307.307,0,0,1,.051.416l-6.229,8.366a.258.258,0,0,1-.193.113Zm-20.611-51.54c-3.6,10.1-8.624,26.394-6.971,27.683,2.3,1.783,25.023,21.016,27.531,23.138l5.855-7.891c-2.678-2.109-17.893-14.154-18.409-15.823s3.658-21.908,4.405-25.5Z"
          transform="translate(280.08 284.933)" fill="#231f20" />
        <path id="Path_2154" data-name="Path 2154"
          d="M180.222,144.73,161.955,199.99s-11.71-3.494-10.906-4.124c-1,.778,12.18-37.511,15.617-53.536Z"
          transform="translate(280.74 283.67)" fill="#231f20" />
        <path id="Path_2155" data-name="Path 2155"
          d="M161.75,200.063a.163.163,0,0,1-.074,0c-5.006-1.491-11-3.44-11.149-4.26a.506.506,0,0,1,0-.131c-.051-.594.64-2.971,4.207-14.26,3.964-12.585,9.405-29.822,11.45-39.359a.278.278,0,0,1,.323-.226l13.556,2.412a.294.294,0,0,1,.2.137.348.348,0,0,1,0,.25l-18.267,55.259A.284.284,0,0,1,161.75,200.063Zm-10.628-4.379c.7.594,5.713,2.305,10.453,3.726l18.063-54.665-12.961-2.305c-2.1,9.62-7.469,26.661-11.4,39.145-2.174,6.893-4.066,12.888-4.2,13.886A.29.29,0,0,1,151.121,195.683Z"
          transform="translate(280.945 283.877)" fill="#231f20" />
        <path id="Path_2156" data-name="Path 2156"
          d="M185.718,112.039l5.272-5.3c-2.831-5.1-3.839-8.55-5.793-9.62-2.525-1.83-11.467-.594-16.84-.309a15.907,15.907,0,0,0-5.051,1.028,29.751,29.751,0,0,0-9.626,6l6.518,8.437,1.552-2.472s-.917,4.819-3.069,14.581a53.948,53.948,0,0,1,22.412,3.434S185.713,113.721,185.718,112.039Z"
          transform="translate(279.576 302.416)" />
        <path id="Path_2157" data-name="Path 2157"
          d="M184.124,112.3a.262.262,0,0,1-.136-.036.289.289,0,0,1-.139-.177.3.3,0,0,1,.026-.227c.057-.113,5.9-11.48,3.964-15.853a4.783,4.783,0,0,0-7.424-.784,16.593,16.593,0,0,0-4.5,10.363,14.356,14.356,0,0,0,.51,4.9.3.3,0,0,1-.18.354.279.279,0,0,1-.352-.158,16.792,16.792,0,0,1,4.111-15.865,4.985,4.985,0,0,1,4.66-1.527,4.932,4.932,0,0,1,3.686,2.472c2.05,4.647-3.743,15.912-3.992,16.388A.276.276,0,0,1,184.124,112.3Z"
          transform="translate(270.179 303.615)" fill="#231f20" />
        <path id="Path_2158" data-name="Path 2158"
          d="M175.231,65.75s-3.528.053-1.863,4.195c1.07,2.692,8.658,3.137,8.658,3.137S181.992,68.085,175.231,65.75Z"
          transform="translate(271.23 314.748)" fill="#231f20" />
        <path id="Path_2159" data-name="Path 2159"
          d="M181.832,73.164h0c-.317,0-7.758-.487-8.9-3.322-.566-1.456-.623-2.608-.108-3.434a2.841,2.841,0,0,1,2.225-1.188h.091c6.9,2.377,6.988,7.4,6.988,7.617a.292.292,0,0,1-.091.214A.268.268,0,0,1,181.832,73.164Zm-6.852-7.332a2.287,2.287,0,0,0-1.7.9,3.247,3.247,0,0,0,.159,2.882c.793,1.967,5.832,2.757,8.086,2.935C181.373,71.441,180.524,67.763,174.98,65.832Z"
          transform="translate(271.423 314.963)" fill="#231f20" />
        <path id="Path_2160" data-name="Path 2160"
          d="M181.679,71.533a5.179,5.179,0,0,1,.3-.481c1.2-1.783,4.383-1.188,6.053-.671a9.356,9.356,0,0,1,4.23,2.418,5.486,5.486,0,0,1,1.6,4,13.152,13.152,0,0,1-1.7,4.3,5.015,5.015,0,0,1-1.6,1.854,4.863,4.863,0,0,1-2.265.594,8.6,8.6,0,0,1-4.926-.957,6.2,6.2,0,0,1-2.967-4.623A11.447,11.447,0,0,1,181.679,71.533Z"
          transform="translate(268.004 313.083)" fill="#fff" />
        <path id="Path_2161" data-name="Path 2161"
          d="M187.223,83.644a8.129,8.129,0,0,1-4.264-1.016,6.471,6.471,0,0,1-3.114-4.866,11.8,11.8,0,0,1,1.342-6.6,5.573,5.573,0,0,1,.311-.5c1.382-2.032,4.915-1.23,6.37-.79a9.56,9.56,0,0,1,4.36,2.5,5.87,5.87,0,0,1,1.7,4.248,12.544,12.544,0,0,1-1.761,4.427,4.247,4.247,0,0,1-4.105,2.567C187.761,83.638,187.489,83.644,187.223,83.644Zm-2.582-13.666a3.074,3.074,0,0,0-2.667,1.064c-.1.149-.2.3-.289.458h0a11.24,11.24,0,0,0-1.263,6.239,5.882,5.882,0,0,0,2.831,4.373,8.166,8.166,0,0,0,4.762.915,4.58,4.58,0,0,0,2.146-.529,4.8,4.8,0,0,0,1.495-1.741,12.033,12.033,0,0,0,1.7-4.159,5.2,5.2,0,0,0-1.523-3.749,8.993,8.993,0,0,0-4.1-2.341,11.52,11.52,0,0,0-3.092-.547Zm-3.2,1.367Z"
          transform="translate(268.246 313.289)" fill="#231f20" />
        <path id="Path_2162" data-name="Path 2162"
          d="M182.743,85.885l-.493,4.29s1.133,2.721,3.8,2.187a5.448,5.448,0,0,0,3.732-2.929l.334-7.837s-1.914-1.188-1.132-5.645a12.568,12.568,0,0,1-6.925.654s-1.427,4.789-1.206,6.958A2.306,2.306,0,0,0,182.743,85.885Z"
          transform="translate(267.8 310.608)" fill="#fff" />
        <path id="Path_2163" data-name="Path 2163"
          d="M185.148,92.527a4.034,4.034,0,0,1-3.4-2.436.317.317,0,0,1,0-.16l.464-4.017a2.691,2.691,0,0,1-1.863-2.525c-.221-2.21,1.132-6.887,1.217-7.083a.3.3,0,0,1,.334-.214,12.393,12.393,0,0,0,6.733-.63.278.278,0,0,1,.3.042.305.305,0,0,1,.1.3c-.73,4.159.98,5.312,1,5.348a.3.3,0,0,1,.136.273l-.328,7.837a.4.4,0,0,1,0,.119,5.726,5.726,0,0,1-3.964,3.1A4.044,4.044,0,0,1,185.148,92.527Zm-2.831-2.608a3.169,3.169,0,0,0,3.443,1.931,5.22,5.22,0,0,0,3.5-2.7l.323-7.612c-.442-.38-1.7-1.783-1.2-5.348a13.073,13.073,0,0,1-6.348.529c-.289,1.01-1.3,4.753-1.132,6.578a2.052,2.052,0,0,0,1.625,2.044.312.312,0,0,1,.266.345Z"
          transform="translate(268.018 310.817)" fill="#231f20" />
        <path id="Path_2164" data-name="Path 2164"
          d="M194.5,84.081a1.85,1.85,0,0,1,.629-.939,2.977,2.977,0,0,1,.951-.44,1.165,1.165,0,0,1,.566-.077,1.1,1.1,0,0,1,.368.16,1.4,1.4,0,0,1,.6.737,1.8,1.8,0,0,1,0,.8,3.5,3.5,0,0,1-.243.832,2.148,2.148,0,0,1-1.216,1.121,2.022,2.022,0,0,1-1.615-.111"
          transform="translate(261.87 307.902)" fill="#fff" />
        <path id="Path_2165" data-name="Path 2165"
          d="M195.252,86.465a2.347,2.347,0,0,1-1.076-.261.305.305,0,0,1-.147-.18.323.323,0,0,1,.017-.236.293.293,0,0,1,.391-.137,1.778,1.778,0,0,0,1.393.107,1.873,1.873,0,0,0,1.042-.969,2.993,2.993,0,0,0,.221-.761,1.475,1.475,0,0,0,0-.648,1.175,1.175,0,0,0-.481-.594.7.7,0,0,0-.266-.119.975.975,0,0,0-.43.065,2.766,2.766,0,0,0-.861.4,1.522,1.522,0,0,0-.527.778.293.293,0,0,1-.365.211.312.312,0,0,1-.2-.383,2.116,2.116,0,0,1,.742-1.1,3.261,3.261,0,0,1,1.042-.493,1.419,1.419,0,0,1,.691-.083,1.3,1.3,0,0,1,.476.2,1.671,1.671,0,0,1,.747.945,2.023,2.023,0,0,1,0,.939,3.387,3.387,0,0,1-.266.915,2.442,2.442,0,0,1-1.382,1.277,2.271,2.271,0,0,1-.759.125Z"
          transform="translate(262.109 308.133)" fill="#231f20" />
        <path id="Path_2166" data-name="Path 2166"
          d="M184,94.513a3.318,3.318,0,0,0,2.752-.79c1.223-1.123,1.132-1.783,1.132-1.783a5.952,5.952,0,0,1-3.647.535Z"
          transform="translate(266.424 304.119)" fill="#231f20" />
        <path id="Path_2167" data-name="Path 2167"
          d="M184.275,94.63a2.95,2.95,0,0,1-.6-.053.3.3,0,0,1-.227-.327l.26-2.062a.27.27,0,0,1,.119-.208.256.256,0,0,1,.227-.042,5.75,5.75,0,0,0,3.46-.511.271.271,0,0,1,.26,0,.3.3,0,0,1,.142.226c0,.089.045.879-1.246,2.062A3.5,3.5,0,0,1,184.275,94.63Zm-.226-.594a3.024,3.024,0,0,0,2.265-.755,4.109,4.109,0,0,0,.889-1.075,5.958,5.958,0,0,1-2.962.38Z"
          transform="translate(266.665 304.341)" fill="#231f20" />
        <path id="Path_2168" data-name="Path 2168" d="M185.828,81.69s-1.557,2.05-1.257,2.454,1.24.238,1.24.238"
          transform="translate(266.194 308.279)" fill="#fff" />
        <path id="Path_2169" data-name="Path 2169"
          d="M185.143,84.513a1.257,1.257,0,0,1-1.031-.392c-.289-.386.136-1.337,1.263-2.816a.275.275,0,0,1,.4-.045.308.308,0,0,1,.042.419,7.6,7.6,0,0,0-1.24,2.127,1.532,1.532,0,0,0,.946.083.289.289,0,0,1,.331.247.3.3,0,0,1-.235.348A2.945,2.945,0,0,1,185.143,84.513Z"
          transform="translate(266.413 308.481)" fill="#231f20" />
        <path id="Path_2170" data-name="Path 2170" d="M186.05,88.2a1.465,1.465,0,0,0,2.038-.873"
          transform="translate(265.535 305.99)" fill="#fff" />
        <path id="Path_2171" data-name="Path 2171"
          d="M186.548,88.395a2.42,2.42,0,0,1-.832-.16.305.305,0,0,1-.153-.392.281.281,0,0,1,.374-.16,1.494,1.494,0,0,0,1.076,0,1.2,1.2,0,0,0,.595-.725.29.29,0,0,1,.218-.218.276.276,0,0,1,.282.111.309.309,0,0,1,.027.315,1.757,1.757,0,0,1-.9,1.064A1.7,1.7,0,0,1,186.548,88.395Z"
          transform="translate(265.756 306.233)" fill="#231f20" />
        <path id="Path_2196" data-name="Path 2196"
          d="M.509,0c.278,0,.5.149.5.34S.777.691.5.7,0,.546,0,.355.231,0,.509,0Z"
          transform="translate(453.89 390.498) rotate(-72.94)" fill="#231f20" />
        <path id="Path_2197" data-name="Path 2197"
          d="M.509,0c.278,0,.5.149.5.34S.777.691.5.7,0,.546,0,.355.231,0,.509,0Z"
          transform="translate(450.286 390.205) rotate(-72.94)" fill="#231f20" />
        <path id="Path_2172" data-name="Path 2172"
          d="M189.518,72.392l5.345,1.188s2.831-7.35-2.673-10.648c-6.229-3.726-9.451,1.563-9.451,1.563Z"
          transform="translate(266.971 316.431)" fill="#fff" />
        <path id="Path_2173" data-name="Path 2173"
          d="M194.609,73.7h-.085l-5.34-1.188a.28.28,0,0,1-.159-.1l-6.795-7.885a.315.315,0,0,1-.028-.374c.034-.053,3.448-5.49,9.841-1.658a7.4,7.4,0,0,1,3.539,6.821,13.263,13.263,0,0,1-.7,4.213A.287.287,0,0,1,194.609,73.7Zm-5.193-1.783,5.006,1.117a13.083,13.083,0,0,0,.617-3.755,6.779,6.779,0,0,0-3.25-6.269c-5.306-3.179-8.29.374-8.93,1.272Z"
          transform="translate(267.225 316.623)" fill="#231f20" />
        <path id="Path_2174" data-name="Path 2174"
          d="M187.028,74.075s-1.059-5.942-6.795-7.891L177,65.75a18.932,18.932,0,0,1,4.683,5.841C183.71,75.346,187.028,74.075,187.028,74.075Z"
          transform="translate(269.461 314.748)" fill="#fff" />
        <path id="Path_2175" data-name="Path 2175"
          d="M185.478,74.371a4.775,4.775,0,0,1-4.3-2.834,18.823,18.823,0,0,0-4.6-5.746.318.318,0,0,1-.1-.362.287.287,0,0,1,.306-.19l3.273.434c5.866,2.02,7.016,8.075,7.027,8.134a.305.305,0,0,1-.187.345A4.334,4.334,0,0,1,185.478,74.371Zm-7.763-8.4a21.216,21.216,0,0,1,3.964,5.259c1.6,2.971,3.964,2.614,4.762,2.418a10.549,10.549,0,0,0-6.523-7.386Z"
          transform="translate(269.697 314.956)" fill="#231f20" />
        <path id="Path_2176" data-name="Path 2176"
          d="M163.715,181.846h-.085a.3.3,0,0,1-.181-.374l4.909-16.714a.281.281,0,0,1,.351-.2.306.306,0,0,1,.187.374l-4.909,16.714A.29.29,0,0,1,163.715,181.846Z"
          transform="translate(275.344 274.654)" fill="#fff" />
        <path id="Path_2177" data-name="Path 2177"
          d="M180.872,203.208a.26.26,0,0,1-.113,0,.285.285,0,0,1-.151-.161.3.3,0,0,1,0-.226l3.964-9.947a.279.279,0,0,1,.368-.16.309.309,0,0,1,.153.386l-3.964,9.953A.281.281,0,0,1,180.872,203.208Z"
          transform="translate(267.904 263.232)" fill="#fff" />
        <path id="Path_2178" data-name="Path 2178"
          d="M207.56,115.9s8.177,17.231,11.965,24.3c0,0,.8,6.185,4.479,5.847,5.226-.487,4.3-3.975,4.3-3.975l-2.944-3-11-25.069"
          transform="translate(256.205 295.167)" fill="#fff" />
        <path id="Path_2179" data-name="Path 2179"
          d="M223.508,146.143c-3.482,0-4.36-5.4-4.456-6.061-3.76-7.005-11.891-24.094-11.948-24.266a.3.3,0,0,1,.157-.338.275.275,0,0,1,.347.094c.085.172,8.222,17.332,11.965,24.29a.394.394,0,0,1,.034.107c0,.059.815,5.9,4.173,5.591,1.835-.172,3.075-.731,3.692-1.658a2.626,2.626,0,0,0,.379-1.866l-2.877-2.971a.29.29,0,0,1-.062-.083L213.9,113.9a.3.3,0,0,1,.142-.392.265.265,0,0,1,.216-.011.283.283,0,0,1,.158.154l10.991,25.027,2.9,2.971a.33.33,0,0,1,.079.137,3.233,3.233,0,0,1-.447,2.436c-.713,1.081-2.1,1.723-4.105,1.907Z"
          transform="translate(256.406 295.378)" fill="#231f20" />
        <path id="Path_2180" data-name="Path 2180"
          d="M204.3,119.707a20.056,20.056,0,0,1-4.23-12.924c.464-7.35,2.831-11.343,2.831-11.343l2.412.636a23.233,23.233,0,0,0-2.882,10.885,20.228,20.228,0,0,0,2.678,10.1Z"
          transform="translate(259.47 302.699)" fill="#231f20" />
        <path id="Path_2181" data-name="Path 2181"
          d="M204.078,119.8a.277.277,0,0,1-.215-.1c-.045-.059-4.762-5.752-4.3-13.143s2.775-11.289,2.871-11.474a.272.272,0,0,1,.306-.137l2.412.624a.3.3,0,0,1,.2.184.316.316,0,0,1-.034.267,23.554,23.554,0,0,0-2.831,10.737,20.211,20.211,0,0,0,2.633,9.953.3.3,0,0,1,0,.25l-.793,2.632a.306.306,0,0,1-.21.2Zm-1.263-24.231a28.837,28.837,0,0,0-2.684,11.022,19.763,19.763,0,0,0,3.833,12.294l.612-1.973a20.59,20.59,0,0,1-2.656-10.172,24.306,24.306,0,0,1,2.724-10.7Z"
          transform="translate(259.687 302.904)" fill="#231f20" />
        <path id="Path_2182" data-name="Path 2182"
          d="M165.1,116.118a48.017,48.017,0,0,1,6.438-19.168l-2.373.339s-3.239,1.83-4.689,9.453C163.124,113.866,165.1,116.118,165.1,116.118Z"
          transform="translate(275.098 302.086)" fill="#231f20" />
        <path id="Path_2183" data-name="Path 2183"
          d="M164.883,116.235a.271.271,0,0,1-.21-.1c-.085-.1-2.055-2.442-.691-9.638,1.461-7.671,4.694-9.578,4.836-9.656h.091l2.373-.339a.284.284,0,0,1,.277.137.3.3,0,0,1,0,.315,48.342,48.342,0,0,0-6.393,19.014.277.277,0,0,1-.187.25A.244.244,0,0,1,164.883,116.235Zm4.162-18.842c-.368.244-3.194,2.311-4.53,9.228-.906,4.753-.3,7.279.164,8.4a49.438,49.438,0,0,1,6.053-17.873Z"
          transform="translate(275.315 302.267)" fill="#231f20" />
        <path id="Path_2184" data-name="Path 2184"
          d="M208.658,141.5a.289.289,0,0,1-.283-.273,17.916,17.916,0,0,1,2.265-10.1.277.277,0,0,1,.506,0,.309.309,0,0,1-.024.31,17.334,17.334,0,0,0-2.2,9.745.3.3,0,0,1-.067.215.273.273,0,0,1-.193.1Z"
          transform="translate(255.872 288.283)" fill="#fff" />
        <path id="Path_2185" data-name="Path 2185"
          d="M172.353,157.1a.265.265,0,0,1-.2-.081.292.292,0,0,1-.082-.2c0-.154-.1-3.72,0-5.4a.3.3,0,0,1,.093-.207.269.269,0,0,1,.207-.072.293.293,0,0,1,.26.315c-.1,1.652,0,5.312,0,5.348a.291.291,0,0,1-.272.3Z"
          transform="translate(271.616 280.096)" fill="#fff" />
        <path id="Path_2186" data-name="Path 2186"
          d="M194.541,154.67a.282.282,0,0,1-.2-.089,6.9,6.9,0,0,1-1.993-5.419.291.291,0,0,1,.323-.244.276.276,0,0,1,.184.118.3.3,0,0,1,.048.221,6.4,6.4,0,0,0,1.846,4.914.307.307,0,0,1,0,.422.264.264,0,0,1-.2.077Z"
          transform="translate(262.82 280.998)" fill="#fff" />
        <path id="Path_2187" data-name="Path 2187"
          d="M125.3,89.387a.287.287,0,0,1-.277-.238c-.26-1.367,1.382-2.733,1.45-2.793a.274.274,0,0,1,.4.048.32.32,0,0,1-.045.422c-.4.327-1.4,1.367-1.246,2.2a.3.3,0,0,1-.039.225.277.277,0,0,1-.182.126Z"
          transform="translate(292.02 306.411)" fill="#231f20" />
        <path id="Path_2198" data-name="Path 2198"
          d="M.842,0l11.37.095a.888.888,0,0,1,.858.9l.043,5.459a.854.854,0,0,1-.844.883L.9,7.238a.888.888,0,0,1-.858-.9L0,.882A.854.854,0,0,1,.842,0Z"
          transform="matrix(0.985, 0.172, -0.172, 0.985, 443.742, 413.725)" fill="#fff" />
        <path id="Path_2188" data-name="Path 2188"
          d="M182.142,131.2a1.079,1.079,0,0,1-.193,0h0l-11.189-2.044a1.162,1.162,0,0,1-.73-.487,1.234,1.234,0,0,1-.193-.891l.895-5.383a1.2,1.2,0,0,1,.464-.767,1.091,1.091,0,0,1,.849-.2l11.183,2.044a1.162,1.162,0,0,1,.736.487,1.235,1.235,0,0,1,.187.891l-.895,5.383A1.148,1.148,0,0,1,182.142,131.2Zm-.1-.594a.569.569,0,0,0,.657-.481l.895-5.383a.617.617,0,0,0-.1-.446.564.564,0,0,0-.369-.243l-11.183-2.044a.569.569,0,0,0-.657.481l-.895,5.383a.617.617,0,0,0,.1.446.564.564,0,0,0,.362.244Z"
          transform="translate(272.575 292.16)" fill="#231f20" />
        <path id="Path_2189" data-name="Path 2189"
          d="M.977,0,5.213.034A1.025,1.025,0,0,1,6.2,1.069l.006.7L0,1.717,0,1.017A.985.985,0,0,1,.971,0Z"
          transform="matrix(0.985, 0.172, -0.172, 0.985, 447.401, 412.641)" fill="#fff" />
        <path id="Path_2190" data-name="Path 2190"
          d="M184.033,122.839h-.045l-6.1-1.117a.276.276,0,0,1-.181-.119.321.321,0,0,1-.051-.226l.113-.689a1.281,1.281,0,0,1,1.467-1.081l4.162.761a1.28,1.28,0,0,1,.821.547,1.389,1.389,0,0,1,.21.992l-.113.689a.3.3,0,0,1-.119.19.263.263,0,0,1-.164.053Zm-5.77-1.652,5.544,1.016.062-.4a.735.735,0,0,0-.113-.547.7.7,0,0,0-.453-.3l-4.168-.761a.7.7,0,0,0-.8.594Z"
          transform="translate(269.177 292.9)" fill="#231f20" />
        <ellipse id="Ellipse_114" data-name="Ellipse 114" cx="2.911" cy="3.054" rx="2.911" ry="3.054"
          transform="translate(446.659 415.406)" fill="#fff" />
        <path id="Path_2191" data-name="Path 2191"
          d="M180.04,130.7a3.5,3.5,0,0,1-.566-.048,3.358,3.358,0,0,1-2.6-3.88,3.213,3.213,0,0,1,3.695-2.73,3.342,3.342,0,0,1,2.6,3.877h0A3.243,3.243,0,0,1,180.04,130.7Zm0-6.114a2.68,2.68,0,0,0-2.639,2.524,2.734,2.734,0,0,0,2.2,2.954,2.644,2.644,0,0,0,3.041-2.246,2.75,2.75,0,0,0-2.14-3.191,2.393,2.393,0,0,0-.47-.036Zm2.877,3.286Z"
          transform="translate(269.535 291.112)" fill="#231f20" />
        <ellipse id="Ellipse_115" data-name="Ellipse 115" cx="1.75" cy="1.836" rx="1.75" ry="1.836"
          transform="translate(447.82 416.624)" fill="#fff" />
        <path id="Path_2192" data-name="Path 2192"
          d="M180.948,130.3a2.262,2.262,0,0,1-.345,0,2.04,2.04,0,0,1-1.313-.9,2.231,2.231,0,0,1-.318-1.612,2.128,2.128,0,0,1,.838-1.379,1.9,1.9,0,0,1,1.518-.357,2.126,2.126,0,0,1,1.653,2.466h0a2.063,2.063,0,0,1-2.033,1.783Zm0-3.678a1.522,1.522,0,0,0-1.343,1.606,1.471,1.471,0,1,0,2.942,0,1.521,1.521,0,0,0-1.343-1.606,1.232,1.232,0,0,0-.255-.024Zm1.7,1.86Z"
          transform="translate(268.621 290.289)" fill="#231f20" />
        <path id="Path_2193" data-name="Path 2193"
          d="M241.772,228.614a.293.293,0,0,1-.277-.238l-5.238-27a.3.3,0,0,1,.221-.351.284.284,0,0,1,.334.232l5.238,27a.3.3,0,0,1-.221.351Z"
          transform="translate(243.76 259.853)" fill="#6d5856" />
        <path id="Path_2194" data-name="Path 2194"
          d="M260.583,228.614a.287.287,0,0,1-.277-.238l-5.561-26.994a.309.309,0,0,1,.037-.225.284.284,0,0,1,.178-.131.3.3,0,0,1,.34.232l5.561,26.994a.309.309,0,0,1-.037.225.284.284,0,0,1-.178.131Z"
          transform="translate(235.742 259.853)" fill="#6d5856" />
        <path id="Path_2195" data-name="Path 2195"
          d="M275.594,225.879a.278.278,0,0,1-.221-.107.32.32,0,0,1,.04-.422l4.655-3.975a.275.275,0,0,1,.4.045.308.308,0,0,1-.042.419l-4.649,3.975A.307.307,0,0,1,275.594,225.879Z"
          transform="translate(226.818 251.62)" fill="#6d5856" />
      </g>
      <text id="Travelling_Txt" transform="translate(440 526)" font-size="18" font-family="Noteworthy-Bold, Noteworthy"
        font-weight="700">
        <tspan x="-35.572" y="0">Travelling</tspan>
      </text>
    </g>
  </svg>
</template>

<script>
export default {
  name: "TravellingInterest"
}
</script>

<style lang="scss" scoped>
:root {
  .interest_travelling_svg {
    #Path_2200 {
      fill: #6c63ff;
      opacity: 0.104;
    }

    #Path_2124,
    #Path_2125,
    #Path_2126,
    #Path_2127,
    #Path_2128 {
      fill: #6d5856;
    }

    #Travelling_Txt {
      fill: #ec615b;
    }

    #Path_2156 {
      fill: #ef7974;
    }
  }
}

:root.dark-theme {
  .interest_travelling_svg {
    #Path_2200 {
      fill: #56575E;
      opacity: 1;
    }

    #Path_2124,
    #Path_2125,
    #Path_2126,
    #Path_2127,
    #Path_2128 {
      fill: #E7EAEC;
    }

    #Travelling_Txt {
      fill: #F8BF7B;
    }

    #Path_2156 {
      fill: #F8BF7B;
    }
  }
}
</style>