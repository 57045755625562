<template>
  <svg class="interest_ride_svg" width="260" height="260" viewBox="0 0 260 260">
    <g id="Group_1652" data-name="Group 1652" transform="translate(-1206 -306.393)">
      <path id="Path_2279" data-name="Path 2279"
        d="M312.956,167.246c.482-19.391-7.5-37.757-15.386-55.255C288.4,91.658,278.617,70.676,261.99,56.6c-20.133-17.086-47.873-21.458-73.348-17.305s-49.252,15.97-72.15,28.537c-13.525,7.427-26.973,15.211-38.983,25.153-7.478,6.192-14.551,13.491-18.091,22.867-3.093,8.131-3.256,17.132-3.377,25.894l-1.275,91.841c-.069,4.994-.121,10.134,1.533,14.808,2.231,6.284,7.211,10.93,12.009,15.3,9.735,8.872,20.193,18.11,32.883,20.123,13.37,2.122,26.655-4.207,40.172-4.573,16.239-.412,31.41,7.738,46.969,12.714a123.815,123.815,0,0,0,60.1,3.823c14.318-2.662,28.722-8.232,38.836-19.29,5.824-6.4,9.959-14.278,13.241-22.446a137.6,137.6,0,0,0,8.968-35.233c1-8.451,4.962-17.379,5.238-25.675C315.007,184.688,312.732,176.045,312.956,167.246Z"
        transform="translate(1151.26 268.561)" fill="#6c63ff" opacity="0.104" style="isolation: isolate" />
      <g id="_52_scooter" data-name="#52_scooter" transform="translate(1202.671 323.489)">
        <path id="Path_2280" data-name="Path 2280"
          d="M214.566,224.339H61.976a.274.274,0,1,1,0-.549H214.566a.281.281,0,0,1,.276.274.275.275,0,0,1-.276.274Z"
          transform="translate(-5.092 -85.26)" />
        <path id="Path_2281" data-name="Path 2281"
          d="M204.869,258.669h-4.153a.274.274,0,1,1,0-.549h4.153a.275.275,0,0,1,.276.275A.281.281,0,0,1,204.869,258.669Z"
          transform="translate(-67.217 -100.746)" />
        <path id="Path_2282" data-name="Path 2282"
          d="M115.523,246.259H108.9a.274.274,0,1,1,0-.549h6.627a.274.274,0,1,1,0,.549Z"
          transform="translate(-26.102 -95.148)" />
        <path id="Path_2283" data-name="Path 2283"
          d="M325.255,236.389h-2.209a.275.275,0,0,1-.276-.274.281.281,0,0,1,.276-.274h2.209a.281.281,0,0,1,.276.274A.275.275,0,0,1,325.255,236.389Z"
          transform="translate(-121.994 -90.695)" />
        <path id="Path_2284" data-name="Path 2284"
          d="M306.88,250.909h-3.313a.274.274,0,1,1,0-.549h3.313a.281.281,0,0,1,.276.274A.275.275,0,0,1,306.88,250.909Z"
          transform="translate(-113.271 -97.245)" />
        <path id="Path_2285" data-name="Path 2285"
          d="M330.4,260.909h-3.313a.274.274,0,1,1,0-.549H330.4a.281.281,0,0,1,.276.275A.275.275,0,0,1,330.4,260.909Z"
          transform="translate(-123.803 -101.756)" />
        <path id="Path_2286" data-name="Path 2286"
          d="M80.8,250.909H77.776a.274.274,0,1,1,0-.549h3.037a.275.275,0,1,1-.017.549Z"
          transform="translate(-12.167 -97.245)" />
        <path id="Path_2287" data-name="Path 2287"
          d="M85.311,233.749H80.5a.274.274,0,1,1,0-.549h4.815a.275.275,0,0,1,.276.274A.281.281,0,0,1,85.311,233.749Z"
          transform="translate(-13.385 -89.505)" />
        <path id="Path_2288" data-name="Path 2288" d="M152.39,265.209h-3a.274.274,0,1,1,0-.549h3a.274.274,0,1,1,0,.549Z"
          transform="translate(-44.232 -103.696)" />
        <path id="Path_2289" data-name="Path 2289"
          d="M217.388,236.389h-3.611a.275.275,0,0,1-.276-.274.281.281,0,0,1,.276-.274h3.634a.281.281,0,0,1,.276.274.274.274,0,0,1-.088.2A.277.277,0,0,1,217.388,236.389Z"
          transform="translate(-73.065 -90.695)" />
        <path id="Path_2290" data-name="Path 2290"
          d="M241.113,250.909h-3.937a.275.275,0,0,1-.276-.274.281.281,0,0,1,.276-.274h3.937a.281.281,0,0,1,.276.274A.275.275,0,0,1,241.113,250.909Z"
          transform="translate(-83.543 -97.245)" />
        <path id="Path_2291" data-name="Path 2291"
          d="M311.691,186.045a.271.271,0,0,1-.243-.143,11.979,11.979,0,0,0-4.605-5.16c-1.878-1.01-4.672-1.169-6.384.472a.278.278,0,0,1-.392-.005.274.274,0,0,1,.005-.39c1.905-1.828,4.97-1.647,7.035-.549a12.447,12.447,0,0,1,4.826,5.385.268.268,0,0,1-.116.368A.256.256,0,0,1,311.691,186.045Z"
          transform="translate(-111.792 -65.255)" />
        <path id="Path_2292" data-name="Path 2292"
          d="M64.669,192.328a.3.3,0,0,1-.188-.066c-.955-.845-.624-2.333-.05-3.354a9.589,9.589,0,0,1,9.515-4.616c.817.121,1.657.615,1.535,1.4a.279.279,0,0,1-.552-.082c.066-.439-.624-.708-1.071-.779a9.035,9.035,0,0,0-8.951,4.347c-.508.911-.729,2.086-.061,2.673a.273.273,0,0,1,0,.384A.282.282,0,0,1,64.669,192.328Z"
          transform="translate(-6.067 -67.405)" />
        <path id="Path_2293" data-name="Path 2293"
          d="M50.7,182.861a.276.276,0,0,1-.271-.231,7.821,7.821,0,0,1,3.629-7.9,7.925,7.925,0,0,1,8.735.279,12.892,12.892,0,0,1,7.444-9.908,9.576,9.576,0,0,1,11.2,3.057,5.724,5.724,0,0,1,1.795-3.447,7.559,7.559,0,0,1,5.981-2.1c3.4.406,6.555,2.871,8.67,6.768a.274.274,0,0,1-.11.373.278.278,0,0,1-.375-.11c-2-3.689-5.08-6.109-8.25-6.483a6.988,6.988,0,0,0-5.522,1.938,4.925,4.925,0,0,0-1.657,4.018.28.28,0,0,1-.2.291.271.271,0,0,1-.32-.143c-1.833-3.645-6.881-5.319-11.044-3.661-3.744,1.5-6.555,5.407-7.179,9.935a.28.28,0,0,1-.171.22.266.266,0,0,1-.276-.044,7.375,7.375,0,0,0-8.284-.61,7.28,7.28,0,0,0-3.55,7.465.28.28,0,0,1-.232.313Z"
          transform="translate(0 -57.647)" />
        <path id="Path_2294" data-name="Path 2294"
          d="M308.272,175.343a.275.275,0,0,1-.276-.263c-.188-4.067-4.362-7.646-8.935-7.685-3.993,0-8.14,2.322-11.044,6.236a.266.266,0,0,1-.331.088.269.269,0,0,1-.16-.3,8.634,8.634,0,0,0-5.064-9.392,7.356,7.356,0,0,0-5.842.428,6.411,6.411,0,0,0-3.38,3.963.275.275,0,0,1-.16.181.289.289,0,0,1-.243,0,10.926,10.926,0,0,0-14.523,4.808.271.271,0,0,1-.364.132.274.274,0,0,1-.138-.362,11.513,11.513,0,0,1,15-5.193A7.041,7.041,0,0,1,276.382,164a7.918,7.918,0,0,1,6.284-.45,9.153,9.153,0,0,1,5.522,9.008c2.96-3.584,6.986-5.687,10.879-5.681,4.854,0,9.283,3.842,9.487,8.184a.28.28,0,0,1-.265.291Z"
          transform="translate(-92.895 -57.885)" />
        <path id="Path_2295" data-name="Path 2295"
          d="M189.738,214.31l-.878,8.969s4.285.769,7.367-.192,4.2-1.378,7.079-.439c6.229,2.036,3.982-5.983,3.982-5.983l-8.449-2.075Z"
          transform="translate(-62.031 -80.983)" />
        <path id="Path_2296" data-name="Path 2296"
          d="M188.294,134.58s11.3.961,15.092,9.524-4.2,38.391-4.2,38.391l-10.139-3.87s3.175-27.692-.3-31.288S167,138.208,167,138.208Z"
          transform="translate(-52.243 -45.018)" />
        <path id="Path_2297" data-name="Path 2297"
          d="M257.782,116.45c-3.534,1.982-7.781,2.2-11.84,2.13a.582.582,0,0,1-.469-.154.546.546,0,0,1-.066-.423q.425-3.985,1.165-7.926a.613.613,0,0,1,.215-.439.555.555,0,0,1,.381-.049,30.912,30.912,0,0,0,3.059.51c.773,0,1.541-.231,2.319-.181a6.488,6.488,0,0,1,1.574.362l2.281.73a3.016,3.016,0,0,1,1.292.659,1.027,1.027,0,0,1,.16,1.317"
          transform="translate(-87.344 -33.738)" />
        <path id="Path_2298" data-name="Path 2298"
          d="M264.91,117.861c-1.4.143-2.96.22-4.031-.681a2.735,2.735,0,0,1-.862-2.706,4.072,4.072,0,0,1,1.1-1.751,4.142,4.142,0,0,1,1.37-.977,4.57,4.57,0,0,1,2.126-.2,5.774,5.774,0,0,1,2.618.763,2.528,2.528,0,0,1,1.022,3.4A3.823,3.823,0,0,1,264.91,117.861Z"
          transform="translate(-93.86 -34.606)" fill="#fff" />
        <path id="Path_2299" data-name="Path 2299"
          d="M263.263,118a4.181,4.181,0,0,1-2.789-.834,2.948,2.948,0,0,1-.95-2.981,4.3,4.3,0,0,1,1.187-1.877,4.454,4.454,0,0,1,1.469-1.037,4.942,4.942,0,0,1,2.253-.225,5.913,5.913,0,0,1,2.761.807,2.769,2.769,0,0,1,1.1,3.766,4.066,4.066,0,0,1-3.573,2.289h0A13.917,13.917,0,0,1,263.263,118Zm1.419-.362Zm-.983-6.087a3.52,3.52,0,0,0-1.314.22,3.955,3.955,0,0,0-1.281.917,3.838,3.838,0,0,0-1.049,1.647,2.444,2.444,0,0,0,.773,2.426c.988.829,2.49.752,3.827.615h0a3.535,3.535,0,0,0,3.148-2.014,2.249,2.249,0,0,0-.928-3.035,5.436,5.436,0,0,0-2.491-.725A3.1,3.1,0,0,0,263.7,111.55Z"
          transform="translate(-93.632 -34.382)" fill="#231f20" />
        <path id="Path_2300" data-name="Path 2300"
          d="M253.615,104.49s4.1,4.007,7.775,4.292a55.978,55.978,0,0,0,6.008.2s2.021,2.492.525,4.347L250.34,113Z"
          transform="translate(-89.561 -31.444)" fill="#fff" />
        <path id="Path_2301" data-name="Path 2301"
          d="M267.7,113.377h0l-17.583-.335a.271.271,0,0,1-.225-.117.268.268,0,0,1-.029-.251l3.28-8.508a.277.277,0,0,1,.447-.1c.039.038,4.07,3.941,7.6,4.221a57.015,57.015,0,0,0,5.975.2.271.271,0,0,1,.226.1c.088.11,2.143,2.684.519,4.688a.26.26,0,0,1-.215.1Zm-17.191-.873,17.053.324c1.038-1.449-.188-3.332-.519-3.793-.552,0-2.761.044-5.9-.2s-6.627-3.156-7.643-4.089Z"
          transform="translate(-89.34 -31.22)" fill="#231f20" />
        <path id="Path_2302" data-name="Path 2302"
          d="M163.131,66S152.357,68.045,150,73.929c-1.585,3.93-3.65,20.7,2.286,37.013l27.644-7.449s1.1-7.355.663-12.12c0,0,15.379,8,17.793,8s10.393-7.471,10.393-7.471l-2.684-4.029s-5.947,4.144-6.627,2.135c-.348-1.043-11.232-13.613-17.793-20.721C178.483,65.811,172.375,65.147,163.131,66Z"
          transform="translate(-43.879 -13.931)" />
        <path id="Path_2303" data-name="Path 2303"
          d="M183.82,57.73l.4,5.088s-1.474,3.162-4.688,2.4a6.655,6.655,0,0,1-4.385-3.639l-.05-9.271s2.369-1.323,1.618-6.587a19,19,0,0,0,9.167,1.262s.685,5.571.32,8.118A2.714,2.714,0,0,1,183.82,57.73Z"
          transform="translate(-55.87 -4.934)" fill="#fff" />
        <path id="Path_2304" data-name="Path 2304"
          d="M180.3,65.4a4.443,4.443,0,0,1-1.038-.126,6.865,6.865,0,0,1-4.583-3.809.223.223,0,0,1,0-.1l-.044-9.271a.269.269,0,0,1,.144-.247s2.209-1.328,1.474-6.318a.285.285,0,0,1,.11-.266.289.289,0,0,1,.288-.035,18.879,18.879,0,0,0,9.012,1.241.288.288,0,0,1,.21.055.3.3,0,0,1,.111.192,44.478,44.478,0,0,1,.32,8.2,3.092,3.092,0,0,1-2.363,2.843l.381,4.83a.257.257,0,0,1,0,.143S182.985,65.4,180.3,65.4Zm-5.075-4.117a6.392,6.392,0,0,0,4.169,3.42,3.881,3.881,0,0,0,4.329-2.2l-.4-5.012a.285.285,0,0,1,.072-.209.277.277,0,0,1,.2-.1,2.449,2.449,0,0,0,2.121-2.4,42.3,42.3,0,0,0-.287-7.762,19.163,19.163,0,0,1-8.57-1.136c.48,4.342-1.165,5.928-1.657,6.312Z"
          transform="translate(-55.659 -4.698)" fill="#231f20" />
        <path id="Path_2305" data-name="Path 2305"
          d="M187.246,69.531a4.086,4.086,0,0,1-3.313-1.065c-1.43-1.383-1.314-2.2-1.314-2.2a7.4,7.4,0,0,0,4.418.8Z"
          transform="translate(-59.235 -14.204)" fill="#231f20" />
        <path id="Path_2306" data-name="Path 2306"
          d="M186.591,69.614a4.277,4.277,0,0,1-2.993-1.175c-1.485-1.438-1.4-2.333-1.392-2.426a.258.258,0,0,1,.149-.209.278.278,0,0,1,.254,0,7.133,7.133,0,0,0,4.2.779.283.283,0,0,1,.221.044.3.3,0,0,1,.116.2l.215,2.448a.28.28,0,0,1-.232.3A3.113,3.113,0,0,1,186.591,69.614Zm-3.733-3.079a4.666,4.666,0,0,0,1.1,1.509,3.829,3.829,0,0,0,2.805,1.015l-.166-1.883a7.4,7.4,0,0,1-3.744-.642Z"
          transform="translate(-59.05 -13.98)" fill="#231f20" />
        <path id="Path_2307" data-name="Path 2307" d="M186.387,53.5s1.524,2.871,1.066,3.293-1.613,0-1.613,0"
          transform="translate(-60.679 -8.443)" fill="#fff" />
        <path id="Path_2308" data-name="Path 2308"
          d="M186.591,57.034a3.332,3.332,0,0,1-1.066-.2.269.269,0,0,1-.16-.351.277.277,0,0,1,.353-.165c.32.121,1.071.285,1.325.049a8.124,8.124,0,0,0-1.1-2.97.273.273,0,0,1,0-.285.277.277,0,0,1,.49.027c.745,1.394,1.519,3.156,1.011,3.628A1.186,1.186,0,0,1,186.591,57.034Z"
          transform="translate(-60.458 -8.206)" fill="#231f20" />
        <path id="Path_2309" data-name="Path 2309"
          d="M184.633,62.417h-.188a3.346,3.346,0,0,1-2.364-1.3.284.284,0,0,1,.039-.39.272.272,0,0,1,.387.033,2.8,2.8,0,0,0,1.971,1.1,3.718,3.718,0,0,0,2.435-.939.283.283,0,0,1,.392,0,.273.273,0,0,1,0,.39A4.359,4.359,0,0,1,184.633,62.417Z"
          transform="translate(-58.969 -11.673)" fill="#231f20" />
        <path id="Path_2349" data-name="Path 2349"
          d="M.741,0c.409,0,.741.141.741.315S1.151.63.741.63,0,.489,0,.315.332,0,.741,0Z"
          transform="translate(123.384 45.293) rotate(-86.38)" fill="#231f20" />
        <path id="Path_2350" data-name="Path 2350"
          d="M.741,0c.409,0,.741.141.741.315S1.151.63.741.63,0,.489,0,.315.332,0,.741,0Z"
          transform="translate(127.675 45.29) rotate(-86.38)" fill="#231f20" />
        <path id="Path_2310" data-name="Path 2310"
          d="M175.657,53.878a4.4,4.4,0,0,0-1.1-1.284,1.451,1.451,0,0,0-.823-.373,1.3,1.3,0,0,0-.7.214,2.7,2.7,0,0,0-1.242,1.778,2.248,2.248,0,0,0,.039,1.032,2.341,2.341,0,0,0,1,1.246,6.564,6.564,0,0,0,1.463.648"
          transform="translate(-54.367 -7.866)" fill="#fff" />
        <path id="Path_2311" data-name="Path 2311"
          d="M174.064,57.186h-.094a7.2,7.2,0,0,1-1.524-.675,2.632,2.632,0,0,1-1.1-1.389,2.373,2.373,0,0,1-.044-1.169,2.964,2.964,0,0,1,1.369-1.965,1.664,1.664,0,0,1,.862-.258,1.788,1.788,0,0,1,.988.434,4.778,4.778,0,0,1,1.176,1.372.282.282,0,0,1-.11.384.285.285,0,0,1-.387-.11,4.161,4.161,0,0,0-1.033-1.2,1.179,1.179,0,0,0-.657-.307,1,1,0,0,0-.552.176,2.4,2.4,0,0,0-1.1,1.581,1.883,1.883,0,0,0,.033.9,2.045,2.045,0,0,0,.884,1.1,6.443,6.443,0,0,0,1.4.62.285.285,0,0,1,.171.362A.282.282,0,0,1,174.064,57.186Z"
          transform="translate(-54.143 -7.645)" fill="#231f20" />
        <path id="Path_2312" data-name="Path 2312"
          d="M170.611,49.108l.055.093a4.076,4.076,0,0,0,1.657,1.789,1.825,1.825,0,0,0,2.209-.379,4.061,4.061,0,0,0,.381-2.163c.094-1.345-.293-3.771.552-4.94.4-.549,1.508-.587,2.1-.659a20,20,0,0,1,3.341-.1,9.517,9.517,0,0,1,1.309.126c.635.11.674.274.723.911.133,1.795.392,3.573.63,5.357a.623.623,0,0,0,.133.351c1.071-.34,1.386-3.392,1.474-4.32a13.3,13.3,0,0,0-.817-5.725,6.526,6.526,0,0,0-1.811-2.591,7.762,7.762,0,0,0-6.643-1.96c-2.872.45-5.594,2.223-6.339,5.116A12.666,12.666,0,0,0,170.611,49.108Z"
          transform="translate(-53.209 0)" />
        <path id="Path_2351" data-name="Path 2351"
          d="M15.2,0c8.394,0,15.2,4.712,15.2,10.522S23.6,21.043,15.2,21.042,0,16.33,0,10.52,6.805,0,15.2,0Z"
          transform="translate(101.201 158.787) rotate(-88.854)" />
        <path id="Path_2354" data-name="Path 2354" d="M0,0,30.4,0v10.52l-30.4,0Z"
          transform="translate(101.111 158.693) rotate(-88.91)" />
        <path id="Path_2355" data-name="Path 2355"
          d="M15.2,0c8.394,0,15.2,4.712,15.2,10.522S23.6,21.043,15.2,21.042,0,16.33,0,10.52,6.805,0,15.2,0Z"
          transform="translate(90.592 158.487) rotate(-88.91)" />
        <path id="Path_2352" data-name="Path 2352"
          d="M10.2,0C15.832,0,20.4,2.357,20.4,5.26s-4.565,5.257-10.2,5.256S0,8.161,0,5.258,4.566,0,10.2,0Z"
          transform="translate(96.039 153.694) rotate(-88.854)" fill="#fff" />
        <path id="Path_2313" data-name="Path 2313"
          d="M137.322,164.907s-1.85.192-2.391-2.2,0-4.227,1.3-4.43,32.642-1.1,33.536.631,1.171,4.594-2.209,6.587S137.322,164.907,137.322,164.907Z"
          transform="translate(-37.79 -55.523)" fill="#fff" />
        <path id="Path_2314" data-name="Path 2314"
          d="M159.961,166.254c-8.786,0-21.9-1.23-22.867-1.323a2.684,2.684,0,0,1-2.651-2.432,5.279,5.279,0,0,1,.326-3.98,1.774,1.774,0,0,1,1.2-.779c.309-.049,32.747-1.317,33.824.774s1.01,4.984-2.33,6.949C166.466,166.045,163.578,166.254,159.961,166.254ZM137.1,164.382h0c.271,0,26.772,2.53,30.052.6,3.794-2.223,2.584-5.33,2.121-6.225-1.1-1.416-31.372-.779-33.244-.489a1.2,1.2,0,0,0-.828.549,4.807,4.807,0,0,0-.249,3.551c.48,2.152,2.032,2.014,2.093,2.009Z"
          transform="translate(-37.566 -55.273)" fill="#231f20" />
        <path id="Path_2315" data-name="Path 2315"
          d="M198.971,116.743a4.191,4.191,0,0,1-2.894-.494,3.441,3.441,0,0,1-1.458-2.745,5.127,5.127,0,0,1,.939-3.013,3.464,3.464,0,0,1,.839-.928,3.058,3.058,0,0,1,2.54-.368,6.434,6.434,0,0,1,2.3,1.263,2.96,2.96,0,0,1,.884.966,3.906,3.906,0,0,1-.331,3.546A3.487,3.487,0,0,1,198.971,116.743Z"
          transform="translate(-64.61 -33.51)" fill="#fff" />
        <path id="Path_2316" data-name="Path 2316"
          d="M197.928,116.865a4.039,4.039,0,0,1-2.209-.6,3.732,3.732,0,0,1-1.579-2.948,5.423,5.423,0,0,1,.983-3.184,3.682,3.682,0,0,1,.911-.994,3.33,3.33,0,0,1,2.761-.412,6.477,6.477,0,0,1,2.408,1.312,3.267,3.267,0,0,1,.961,1.059,4.035,4.035,0,0,1-.337,3.8,3.763,3.763,0,0,1-3.026,1.9h0A5.916,5.916,0,0,1,197.928,116.865Zm-.116-7.718a2.49,2.49,0,0,0-1.474.439,3.212,3.212,0,0,0-.773.851,4.919,4.919,0,0,0-.889,2.854,3.178,3.178,0,0,0,1.331,2.519,4.014,4.014,0,0,0,2.706.45h0a3.227,3.227,0,0,0,2.623-1.647,3.71,3.71,0,0,0,.32-3.293,2.78,2.78,0,0,0-.8-.856,6.042,6.042,0,0,0-2.209-1.213,3.417,3.417,0,0,0-.828-.1Z"
          transform="translate(-64.395 -33.297)" fill="#231f20" />
        <path id="Path_2317" data-name="Path 2317"
          d="M221.266,98.548s-6-.088-9.228,2.2-3.518,3.508-7.251,4.578-12.386.895-12.386.895l-.072,3.842,17.881.966a24.417,24.417,0,0,1,.729,4.611,3.7,3.7,0,0,0,1.381,2.668l13.358.521s1.684-7.855,3.457-9.145C231.576,107.89,224.177,98.372,221.266,98.548Z"
          transform="translate(-63.585 -28.763)" fill="#fff" />
        <path id="Path_2318" data-name="Path 2318"
          d="M225.459,118.87h0l-13.369-.549a.271.271,0,0,1-.155-.06,3.923,3.923,0,0,1-1.491-2.887,22.522,22.522,0,0,0-.674-4.347l-17.671-.95a.275.275,0,0,1-.26-.28l.072-3.842a.274.274,0,0,1,.088-.187.255.255,0,0,1,.193-.077c.088,0,8.653.159,12.3-.889a10.576,10.576,0,0,0,4.749-2.635c.646-.549,1.381-1.169,2.419-1.9,3.225-2.278,9.012-2.24,9.388-2.234,2.347-.143,7.019,5.132,8.283,8.673.652,1.789.215,2.585-.265,2.937-1.386,1.01-2.894,6.828-3.358,8.986A.27.27,0,0,1,225.459,118.87ZM212.206,117.8l13.032.51c.3-1.328,1.833-7.86,3.518-9.084.6-.439.37-1.5.072-2.311-1.27-3.5-5.749-8.316-7.7-8.316h-.088c-.061,0-5.947-.066-9.062,2.141a29.989,29.989,0,0,0-2.38,1.872,11.168,11.168,0,0,1-4.97,2.745c-3.4.977-10.658.933-12.187.911l-.061,3.293,17.61.95a.27.27,0,0,1,.254.2,24.126,24.126,0,0,1,.74,4.688,3.385,3.385,0,0,0,1.22,2.366Z"
          transform="translate(-63.366 -28.528)" fill="#231f20" />
        <ellipse id="Ellipse_121" data-name="Ellipse 121" cx="6.373" cy="6.334" rx="6.373" ry="6.334"
          transform="translate(151.143 69.785)" />
        <ellipse id="Ellipse_122" data-name="Ellipse 122" cx="6.373" cy="6.334" rx="6.373" ry="6.334"
          transform="translate(154.506 69.846)" />
        <path id="Path_2356" data-name="Path 2356" d="M0,0H12.669V3.365H0Z"
          transform="translate(157.404 82.454) rotate(-88.91)" />
        <ellipse id="Ellipse_123" data-name="Ellipse 123" cx="5.279" cy="5.248" rx="5.279" ry="5.248"
          transform="translate(155.599 70.932)" />
        <path id="Path_2319" data-name="Path 2319"
          d="M187.27,189.419a1.524,1.524,0,0,1-.287.06H144.589c-3.866-1.219-5-3.842-6.693-8.568l-16.776-.318s2.441-13.36,6.367-20.353,4.733-7.2,4.733-7.2,32-.368,31.974.779-3.242,17.812-5.981,22.906-1.657,8.881.4,9.26,7.681-.225,10.006-1.438,3.313-40.032,3.772-45.279c.287-3.392,2.071-4.2,4.893-5.319a13.64,13.64,0,0,0,1.331-.664,16.246,16.246,0,0,1,8.466-1.724c6.13.351,10.365,1.564,12.243,5.374s6.367,15.885,6.892,18.526,1.1,8.475-3.645,16.27S188.088,188.98,187.27,189.419Z"
          transform="translate(-31.699 -43.638)" fill="#fff" />
        <path id="Path_2320" data-name="Path 2320"
          d="M144.383,189.593H144.3c-3.948-1.235-5.13-3.93-6.8-8.563l-16.567-.313a.282.282,0,0,1-.21-.1.273.273,0,0,1-.055-.225c0-.132,2.491-13.487,6.395-20.436,3.7-6.587,4.688-7.279,4.909-7.339h.066c5.174-.06,31.046-.3,32.139.8a.356.356,0,0,1,.11.258c-.028,1.345-3.264,17.911-6.014,23.054-1.9,3.529-1.861,6.274-1.187,7.685a2.034,2.034,0,0,0,1.392,1.208c1.927.357,7.516-.209,9.824-1.416,1.657-.856,2.761-25.98,3.214-36.711.177-3.974.315-7.136.42-8.354.293-3.5,2.115-4.391,5.064-5.549a8.5,8.5,0,0,0,.983-.478l.315-.17a16.568,16.568,0,0,1,8.615-1.757c5.207.3,10.327,1.18,12.475,5.528,1.861,3.76,6.384,15.918,6.914,18.591,1.022,5.143-.254,10.835-3.683,16.467-4.871,8-14.6,17.356-15.4,17.785h0a1.054,1.054,0,0,1-.376.093Zm42.681-.335Zm-42.637-.214h42.35l.166-.033c.834-.489,10.492-9.88,15.181-17.565,3.357-5.517,4.606-11.071,3.611-16.077a155.343,155.343,0,0,0-6.864-18.46c-1.657-3.293-5.119-4.825-12.016-5.226a16.013,16.013,0,0,0-8.322,1.7l-.309.165a9.648,9.648,0,0,1-1.044.51c-2.833,1.1-4.451,1.9-4.721,5.088-.1,1.23-.243,4.364-.42,8.332-.977,22.329-1.905,36.332-3.5,37.166-2.446,1.279-8.134,1.844-10.183,1.466a2.536,2.536,0,0,1-1.789-1.5c-.944-1.96-.5-5.006,1.2-8.157,2.667-4.94,5.76-20.952,5.942-22.67-1.6-.692-19.637-.752-31.631-.615-.1.088-1.1.966-4.556,7.064-3.523,6.279-5.876,17.894-6.273,19.958l16.451.307a.276.276,0,0,1,.254.181C139.623,185.35,140.728,187.864,144.428,189.044Z"
          transform="translate(-31.493 -43.471)" fill="#231f20" />
        <path id="Path_2353" data-name="Path 2353"
          d="M15.2,0c8.394,0,15.2,3.965,15.2,8.854s-6.8,8.851-15.2,8.85S0,13.741,0,8.853,6.805,0,15.2,0Z"
          transform="translate(163.894 158.747) rotate(-88.854)" />
        <path id="Path_2357" data-name="Path 2357" d="M0,0,30.4,0V8.857L0,8.853Z"
          transform="translate(163.737 158.653) rotate(-88.91)" />
        <path id="Path_2358" data-name="Path 2358"
          d="M15.2,0c8.394,0,15.2,3.965,15.2,8.854s-6.8,8.851-15.2,8.85S0,13.741,0,8.853,6.805,0,15.2,0Z"
          transform="translate(154.891 158.485) rotate(-88.91)" />
        <path id="Path_2359" data-name="Path 2359"
          d="M10.2,0c5.633,0,10.2,1.982,10.2,4.424S15.832,8.849,10.2,8.848,0,6.867,0,4.424,4.566,0,10.2,0Z"
          transform="translate(159.414 153.569) rotate(-88.91)" fill="#fff" />
        <path id="Path_2321" data-name="Path 2321"
          d="M249.655,182.153s5.55-1.1,8.581,2.344,6.831,12.175,5.953,13.015-16.644.2-20.984.549l-6.047,5.286s-.718-6.7.756-11.472S240.223,183.778,249.655,182.153Z"
          transform="translate(-83.58 -66.402)" fill="#fff" />
        <path id="Path_2322" data-name="Path 2322"
          d="M236.963,203.4a.293.293,0,0,1-.1,0,.269.269,0,0,1-.177-.225c-.028-.274-.723-6.806.746-11.582l.088-.285c1.43-4.644,2.463-8,11.867-9.622.282-.055,5.754-1.1,8.836,2.432,2.822,3.211,7.085,12.3,5.936,13.393-.552.549-4.329.549-12.221.549-3.639,0-7.085-.033-8.835.093l-5.986,5.226A.278.278,0,0,1,236.963,203.4Zm14.512-21.358a11.363,11.363,0,0,0-1.988.159h0c-9.073,1.564-10.012,4.622-11.436,9.244l-.083.291c-1.2,3.892-.911,9.156-.779,10.83l5.644-4.94a.266.266,0,0,1,.16-.071c1.734-.137,5.246-.121,8.963-.1,4.755,0,11.265.055,11.834-.373.552-.675-2.761-8.964-5.97-12.625C256.037,182.44,253.342,182.044,251.475,182.044Zm-2.038-.11Z"
          transform="translate(-83.363 -66.184)" fill="#231f20" />
        <path id="Path_2323" data-name="Path 2323"
          d="M254.66,158.494a.27.27,0,0,1-.276-.258c-.674-12.471-4.821-19.486-4.865-19.558a.277.277,0,0,1,.3-.408.271.271,0,0,1,.17.128c.044.071,4.258,7.185,4.97,19.8a.273.273,0,0,1-.071.2.276.276,0,0,1-.194.09Z"
          transform="translate(-89.176 -46.679)" fill="#6d5856" />
        <path id="Path_2324" data-name="Path 2324"
          d="M258.462,198.841a.276.276,0,0,1-.271-.214c0-.071-1.585-7.053-4.882-9.771a.274.274,0,0,1,.069-.49.277.277,0,0,1,.285.073c3.44,2.832,5,9.776,5.064,10.072a.275.275,0,0,1-.21.324Z"
          transform="translate(-90.836 -69.275)" fill="#231f20" />
        <path id="Path_2325" data-name="Path 2325"
          d="M179.176,230.618h0l-11.84-.22a.274.274,0,1,1,0-.549l11.84.225a.274.274,0,1,1,0,.549Z"
          transform="translate(-52.27 -87.993)" fill="#231f20" />
        <path id="Path_2326" data-name="Path 2326"
          d="M152.031,202c-2.308,0-4.191-.1-4.368-.11a.286.286,0,0,1-.26-.291.274.274,0,0,1,.092-.189.277.277,0,0,1,.2-.069c.1,0,9.979.549,12.546-.84.414-.22.657-.944.718-2.1.249-4.814-2.761-14.4-3.507-14.908-.867-.587-8.212-1.043-12.939-1.208a.28.28,0,0,1-.265-.285.292.292,0,0,1,.287-.263c1.22.044,11.95.445,13.226,1.306,1.149.774,3.981,10.8,3.75,15.369-.072,1.394-.4,2.229-1.005,2.552C158.956,201.819,155.112,202,152.031,202Z"
          transform="translate(-42.056 -66.291)" fill="#231f20" />
        <path id="Path_2327" data-name="Path 2327"
          d="M200.3,108.882a1.236,1.236,0,0,1,1.1-.384,2.825,2.825,0,0,1,1.138.483,1.342,1.342,0,0,1,1.778-.214.788.788,0,0,1,1.016-.049,1.966,1.966,0,0,1,.63.895,11.033,11.033,0,0,1,.757,2.53,1.86,1.86,0,0,1,0,1,.74.74,0,0,1-.779.505,1,1,0,0,1-.552-.472,4.925,4.925,0,0,1-.679-1.5c.188.631.337,1.422-.166,1.855a1.193,1.193,0,0,1-1.491-.1,3.372,3.372,0,0,1-.856-1.361,3.347,3.347,0,0,1-.16,1.7.28.28,0,0,1-.138.176.289.289,0,0,1-.21-.044,4.428,4.428,0,0,1-1.579-2.053,2.124,2.124,0,0,1,.215,1.2.745.745,0,0,1-.911.6,1.038,1.038,0,0,1-.464-.549,12.1,12.1,0,0,1-.773-1.877,2.568,2.568,0,0,1,.11-2.256A1.531,1.531,0,0,1,200.3,108.882Z"
          transform="translate(-66.114 -33.245)" fill="#fff" />
        <path id="Path_2328" data-name="Path 2328"
          d="M201.623,113.978a.6.6,0,0,1-.3-.088,3.068,3.068,0,0,1-.988-.928,1.155,1.155,0,0,1-.552.642.838.838,0,0,1-.712.044,1.271,1.271,0,0,1-.6-.653,12.16,12.16,0,0,1-.8-1.921,2.837,2.837,0,0,1,.16-2.509,1.549,1.549,0,0,1,1.071-.549,1.931,1.931,0,0,1,1.154.253,1.542,1.542,0,0,1,1.193-.3,2.81,2.81,0,0,1,1.055.4,1.665,1.665,0,0,1,1.795-.2,1.11,1.11,0,0,1,1.2.06,2.24,2.24,0,0,1,.729,1.015,10.931,10.931,0,0,1,.773,2.591,2.146,2.146,0,0,1-.033,1.136,1.016,1.016,0,0,1-1.1.681,1.236,1.236,0,0,1-.729-.576l-.083-.121a1.191,1.191,0,0,1-.353.549,1.487,1.487,0,0,1-1.85-.1,2.2,2.2,0,0,1-.409-.445,3.106,3.106,0,0,1-.177.664.55.55,0,0,1-.3.34A.456.456,0,0,1,201.623,113.978Zm-1.739-2.651a.271.271,0,0,1,.249.154,4.147,4.147,0,0,0,1.43,1.91,3.078,3.078,0,0,0,.138-1.52.28.28,0,0,1,.2-.307.265.265,0,0,1,.326.159,3.077,3.077,0,0,0,.779,1.252.938.938,0,0,0,1.138.11c.271-.242.315-.681.133-1.383l-.055-.2a.267.267,0,0,1,.028-.2.27.27,0,0,1,.166-.125.276.276,0,0,1,.337.187,1.02,1.02,0,0,0,.055.2,4.646,4.646,0,0,0,.591,1.224c.094.137.237.329.4.362s.392-.148.469-.34a1.637,1.637,0,0,0,0-.851,10.694,10.694,0,0,0-.734-2.47,1.745,1.745,0,0,0-.552-.779.516.516,0,0,0-.652,0,.277.277,0,0,1-.375.038,1.065,1.065,0,0,0-1.4.165.283.283,0,0,1-.364.06,2.692,2.692,0,0,0-1.027-.445.959.959,0,0,0-.872.285.283.283,0,0,1-.392.038,1.342,1.342,0,0,0-.955-.274.974.974,0,0,0-.69.329c-.453.549-.193,1.586-.066,2a11.081,11.081,0,0,0,.762,1.833c.083.165.182.346.32.4a.278.278,0,0,0,.243,0,.583.583,0,0,0,.293-.379,1.938,1.938,0,0,0-.2-1.043.268.268,0,0,1,.144-.346.283.283,0,0,1,.1-.044Z"
          transform="translate(-65.881 -33.006)" fill="#231f20" />
        <path id="Path_2329" data-name="Path 2329"
          d="M267.086,109.584a1.375,1.375,0,0,1,1.1-.362,2.891,2.891,0,0,1,1.137.483,1.337,1.337,0,0,1,1.773-.214.8.8,0,0,1,1.022-.049,2.025,2.025,0,0,1,.629.9,10.933,10.933,0,0,1,.757,2.525,1.969,1.969,0,0,1,0,1,.751.751,0,0,1-.779.51,1.031,1.031,0,0,1-.58-.478,4.931,4.931,0,0,1-.679-1.5c.188.631.331,1.422-.166,1.855a1.2,1.2,0,0,1-1.5-.1,3.416,3.416,0,0,1-.85-1.361c.144.34.116,1.153-.293,1.323s-1.1-.318-1.38-.648a6.282,6.282,0,0,1-.4-.829c-.066.269,0,.549-.05.834a1.1,1.1,0,0,1-.1.373.447.447,0,0,1-.293.236.483.483,0,0,1-.265,0,1.2,1.2,0,0,1-.657-.582,5.385,5.385,0,0,1-.469-1.74,2.066,2.066,0,0,0-.652-1.592.463.463,0,0,1-.155-.137c-.215-.439,1.2-.725,1.458-.752a2.053,2.053,0,0,1,1.386.307Z"
          transform="translate(-95.776 -33.574)" fill="#fff" />
        <path id="Path_2330" data-name="Path 2330"
          d="M270.454,114.511a1.663,1.663,0,0,1-1.049-.379,2.205,2.205,0,0,1-.431-.478.852.852,0,0,1-.431.483c-.608.252-1.336-.28-1.69-.714a1.215,1.215,0,0,1-.11.318.733.733,0,0,1-.475.379.782.782,0,0,1-.414-.033,1.471,1.471,0,0,1-.812-.708,5.7,5.7,0,0,1-.5-1.844v-.071a1.729,1.729,0,0,0-.552-1.323.649.649,0,0,1-.248-.242.469.469,0,0,1,0-.445c.287-.516,1.657-.7,1.657-.7a2.32,2.32,0,0,1,1.381.264,1.614,1.614,0,0,1,1.193-.3,2.8,2.8,0,0,1,1.06.406,1.665,1.665,0,0,1,1.795-.2,1.11,1.11,0,0,1,1.2.06,2.239,2.239,0,0,1,.729,1.015,11.472,11.472,0,0,1,.773,2.591,2.091,2.091,0,0,1-.039,1.136,1.011,1.011,0,0,1-1.1.681,1.247,1.247,0,0,1-.751-.587l-.083-.126a1.239,1.239,0,0,1-.353.549A1.208,1.208,0,0,1,270.454,114.511Zm-1.728-2.2a.276.276,0,0,1,.254.17,3.19,3.19,0,0,0,.773,1.257.943.943,0,0,0,1.138.1c.276-.242.32-.681.138-1.389a.882.882,0,0,0-.055-.2.269.269,0,0,1,.193-.329.276.276,0,0,1,.337.187.882.882,0,0,0,.055.2,4.586,4.586,0,0,0,.585,1.23c.1.154.243.329.4.362s.4-.148.469-.34a1.7,1.7,0,0,0,0-.851,10.7,10.7,0,0,0-.74-2.47,1.745,1.745,0,0,0-.552-.779.522.522,0,0,0-.652,0,.277.277,0,0,1-.375.038,1.065,1.065,0,0,0-1.4.165.277.277,0,0,1-.359.06,2.73,2.73,0,0,0-1.027-.445,1.108,1.108,0,0,0-.906.291.278.278,0,0,1-.337.033,1.775,1.775,0,0,0-1.2-.285,3.378,3.378,0,0,0-1.2.373,2.19,2.19,0,0,1,.784,1.724v.077a5.067,5.067,0,0,0,.442,1.647.859.859,0,0,0,.491.445.282.282,0,0,0,.127,0,.209.209,0,0,0,.1-.1.866.866,0,0,0,.077-.28,2.1,2.1,0,0,0,0-.329,2.226,2.226,0,0,1,.039-.549.276.276,0,0,1,.287-.165.271.271,0,0,1,.276.126,2.183,2.183,0,0,1,.177.39,1.592,1.592,0,0,0,.2.412c.254.313.779.686,1.06.549s.26-.686.144-.966a.274.274,0,0,1,.149-.357.238.238,0,0,1,.094-.027Z"
          transform="translate(-95.538 -33.347)" fill="#231f20" />
        <path id="Path_2331" data-name="Path 2331"
          d="M155.527,144.735c-.657,1.866,1.657,5.066,3.264,5.462,3.1.895,9.99,1.2,11.348,2.251s4.512,29.707,4.512,29.707l9.211-.845c.8-5.094,5.693-33.412-1.938-37.655-2.065-1.147-10.939-3.123-10.939-3.123s-14.407,2.745-15.192,3.722A1.642,1.642,0,0,0,155.527,144.735Z"
          transform="translate(-47.054 -47.702)" />
        <path id="Path_2332" data-name="Path 2332"
          d="M145.576,193.515h0l-6.665-.126a.272.272,0,0,1-.2-.083.269.269,0,0,1-.075-.2.263.263,0,0,1,.081-.2.266.266,0,0,1,.2-.073l6.665.126a.274.274,0,1,1,0,.549Z"
          transform="translate(-39.544 -71.298)" fill="#6d5856" />
        <path id="Path_2333" data-name="Path 2333"
          d="M145.44,200.692h0l-6.66-.143a.274.274,0,0,1,0-.549l6.665.126a.274.274,0,1,1,0,.549Z"
          transform="translate(-39.486 -74.528)" fill="#6d5856" />
        <path id="Path_2334" data-name="Path 2334"
          d="M205.721,92.426a.272.272,0,0,1-.193-.081.268.268,0,0,1-.077-.194V78.774a.276.276,0,0,1,.552,0V92.168A.275.275,0,0,1,205.721,92.426Z"
          transform="translate(-69.46 -19.72)" fill="#231f20" />
        <path id="Path_2335" data-name="Path 2335"
          d="M178.092,95.626c-5.274,0-12.11-.549-13.585-3.348-2.049-3.875-2.474-13.119-2.507-13.5a.276.276,0,0,1,.552,0c0,.093.436,9.507,2.43,13.278,1.165,2.2,6.881,3.293,15.689,3.008h.293a.274.274,0,1,1,0,.549h-.271C179.9,95.6,179.025,95.626,178.092,95.626Z"
          transform="translate(-50.004 -19.72)" fill="#231f20" />
        <path id="Path_2336" data-name="Path 2336"
          d="M162.288,127.745a11.931,11.931,0,0,1-4.893-.884.275.275,0,1,1,.226-.5c6.892,3.123,22.277-3.6,22.431-3.667a.277.277,0,0,1,.349.144.274.274,0,0,1-.123.356,72.168,72.168,0,0,1-8.714,3.052A35.914,35.914,0,0,1,162.288,127.745Z"
          transform="translate(-47.862 -39.649)" fill="#231f20" />
        <path id="Path_2337" data-name="Path 2337"
          d="M199.331,214.639h0l-9.1-.28a.274.274,0,1,1,0-.549l9.1.28a.281.281,0,0,1,.271.285A.275.275,0,0,1,199.331,214.639Z"
          transform="translate(-62.524 -80.758)" fill="#fff" />
        <path id="Path_2338" data-name="Path 2338"
          d="M199.6,153.547a.27.27,0,0,1-.271-.269c0-.049-.127-5.363-2.115-8.59s-8.554-2.893-8.62-2.893a.274.274,0,1,1,0-.549c.282,0,7-.274,9.112,3.151s2.209,8.645,2.209,8.865a.268.268,0,0,1-.075.2.271.271,0,0,1-.2.083Z"
          transform="translate(-61.79 -48.022)" fill="#fff" />
        <path id="Path_2339" data-name="Path 2339"
          d="M173.1,45.34a8.628,8.628,0,0,1,4.418-4.677c3.429-1.647,8.1-1.509,8.692-.714s1.657,5.138,1.176,5.2-8.051-1.01-11.525,1.334S173.1,45.34,173.1,45.34Z"
          transform="translate(-54.948 -2.073)" fill="#fff" />
        <path id="Path_2340" data-name="Path 2340"
          d="M173.713,47.342a1.143,1.143,0,0,1-.63-.176c-.784-.549-.514-1.949-.475-2.108a.06.06,0,0,1,0-.027,8.8,8.8,0,0,1,4.545-4.836c3.473-1.647,8.283-1.6,9.029-.631.585.779,1.6,4.682,1.292,5.4a.386.386,0,0,1-.3.242,6.231,6.231,0,0,1-.812-.038c-2.126-.159-7.764-.582-10.592,1.323a4.061,4.061,0,0,1-2.054.856Zm-.552-2.152c-.066.324-.149,1.252.248,1.52.2.132.734.22,2.071-.681,2.987-2.009,8.764-1.581,10.939-1.416l.585.038a13.581,13.581,0,0,0-1.243-4.759c-.53-.708-4.97-.812-8.35.8a8.43,8.43,0,0,0-4.269,4.5Z"
          transform="translate(-54.72 -1.852)" fill="#231f20" />
        <path id="Path_2341" data-name="Path 2341"
          d="M181.595,44.475A.274.274,0,0,1,181.4,44l2.38-2.311a.273.273,0,1,1,.387.384l-2.38,2.316A.3.3,0,0,1,181.595,44.475Z"
          transform="translate(-58.654 -3.079)" fill="#d1d3d4" />
        <path id="Path_2342" data-name="Path 2342"
          d="M190.147,44.889a.26.26,0,0,1-.193-.077.268.268,0,0,1,0-.384c.944-.977,2.408-2.509,2.573-2.717a.277.277,0,0,1,.486.055.273.273,0,0,1-.05.28c-.232.3-2.369,2.508-2.612,2.745A.282.282,0,0,1,190.147,44.889Z"
          transform="translate(-62.485 -3.071)" fill="#d1d3d4" />
        <path id="Path_2343" data-name="Path 2343"
          d="M185.356,96.354a.27.27,0,0,1-.271-.269l-.326-13.8a.268.268,0,0,1,.073-.2.271.271,0,0,1,.192-.085.281.281,0,0,1,.282.269l.326,13.805a.275.275,0,0,1-.265.28Z"
          transform="translate(-60.195 -21.299)" fill="#231f20" />
        <path id="Path_2344" data-name="Path 2344"
          d="M186.2,133.427a.27.27,0,0,1-.271-.236l-.552-4.331a.268.268,0,0,1,.055-.2.271.271,0,0,1,.182-.1.276.276,0,0,1,.309.231l.552,4.331a.275.275,0,0,1-.237.307Z"
          transform="translate(-60.471 -42.3)" fill="#231f20" />
        <path id="Path_2345" data-name="Path 2345"
          d="M214.08,85.06a3.073,3.073,0,0,1-.707-.082,2.6,2.6,0,0,1-1.656-1.323,3.042,3.042,0,0,1-.4-2.487,2.58,2.58,0,0,1,1.325-1.416.276.276,0,0,1,.326.157.274.274,0,0,1-.11.343,2.047,2.047,0,0,0-1.022,1.1,2.524,2.524,0,0,0,.365,2.036,2.068,2.068,0,0,0,1.32,1.059,4.73,4.73,0,0,0,3.059-.741.277.277,0,0,1,.381.082.268.268,0,0,1-.077.373A5.8,5.8,0,0,1,214.08,85.06Z"
          transform="translate(-72.037 -20.281)" fill="#231f20" />
        <rect id="Rectangle_384" data-name="Rectangle 384" width="24.767" height="9.661" rx="1.73"
          transform="translate(153.959 105.25)" fill="#fff" />
        <path id="Path_2346" data-name="Path 2346"
          d="M261.572,172.874H238.721a1.228,1.228,0,0,1-1.231-1.224v-7.756a1.228,1.228,0,0,1,1.231-1.224h22.851a1.228,1.228,0,0,1,1.231,1.224v7.74a1.22,1.22,0,0,1-.355.876A1.235,1.235,0,0,1,261.572,172.874Zm-22.851-9.661a.683.683,0,0,0-.679.681v7.74a.677.677,0,0,0,.679.675h22.851a.683.683,0,0,0,.685-.675v-7.74a.688.688,0,0,0-.685-.681Z"
          transform="translate(-83.807 -57.689)" fill="#231f20" />
        <path id="Path_2347" data-name="Path 2347"
          d="M168.368,69.009a.848.848,0,0,0-1.132-.3,33.352,33.352,0,0,0-5.114,2.964.814.814,0,1,0,.839,1.394,33.036,33.036,0,0,1,5.108-2.964.821.821,0,0,0,.3-1.1Z"
          transform="translate(-49.883 -15.258)" fill="#231f20" />
        <path id="Path_2348" data-name="Path 2348"
          d="M200.53,71.077l-5.163-1.252a.826.826,0,1,0-.442,1.592l5.163,1.235a.832.832,0,0,0,.82-.194.822.822,0,0,0-.378-1.392Z"
          transform="translate(-64.474 -15.794)" fill="#231f20" />
      </g>
      <text id="Riding_Txt" transform="translate(1336 526)" font-size="18" font-family="Noteworthy-Bold, Noteworthy"
        font-weight="700">
        <tspan x="-24.84" y="0">Riding</tspan>
      </text>
    </g>
  </svg>
</template>

<script>
export default {
  name: "RidingInterest"
}
</script>

<style lang="scss" scoped>
:root {
  .interest_ride_svg {
    #Path_2279 {
      fill: #6c63ff;
      opacity: 0.104;
    }

    #Riding_Txt {
      fill: #ec615b;
    }

    #Ellipse_123,
    #Path_2297,
    #Path_2302 {
      fill: #ef7974;
    }

    #Path_2280,
    #Path_2281,
    #Path_2282,
    #Path_2283,
    #Path_2284,
    #Path_2285,
    #Path_2286,
    #Path_2287,
    #Path_2288,
    #Path_2289,
    #Path_2290,
    #Path_2291,
    #Path_2292,
    #Path_2293,
    #Path_2294 {
      fill: #6d5856;
    }
  }
}

:root.dark-theme {
  .interest_ride_svg {
    #Path_2279 {
      fill: #56575E;
      opacity: 1;
    }

    #Riding_Txt {
      fill: #F8BF7B;
    }

    #Ellipse_123,
    #Path_2297,
    #Path_2302 {
      fill: #F8BF7B;
    }

    #Path_2280,
    #Path_2281,
    #Path_2282,
    #Path_2283,
    #Path_2284,
    #Path_2285,
    #Path_2286,
    #Path_2287,
    #Path_2288,
    #Path_2289,
    #Path_2290,
    #Path_2291,
    #Path_2292,
    #Path_2293,
    #Path_2294 {
      fill: #E7EAEC;
    }
  }
}
</style>
